import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import icon_margin from "assets/icons/icon_margin.svg";
import icon_contract from "assets/icons/icon_contract.svg";
import icon_etf from "assets/icons/icon_etf.svg";

const News = observer(() => {
  const navigate = useNavigate();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  return (
    <div className="news">
      <p className="why-title">Latest BITTOKEN news</p>
      <ul className="news-item">
        <li>
          <p className="news-title">
            BITTOKEN records more than 300% in derivatives trading growth
          </p>
          <p className="news-desc">
            Leading global cryptocurrency exchange BITTOKEN announced the
            platform’s derivatives trading volume reached an all-time high of
            $8.69 billion as of Feb. 28, 2022 — more than 300% compared to the
            previous year. BITTOKEN is also ranked fifth globally in terms of
            its trading volume, according to CoinMarketCap.
          </p>
        </li>
        <li>
          <p className="news-title">
            BITTOKEN: Defying the crypto odds in 2021
          </p>
          <p className="news-desc">
            As the year comes to an end, we can look back and say that 2021 was
            a pivotal year for the crypto industry. Significant milestones have
            been achieved, with Bitcoin reaching new all-time highs on multiple
            occasions, the nonfungible token boom and decentralized finance
            seeing widespread adoption by central governments and traditional
            financial institutions. Cryptocurrency exchanges have played a
            significant role in the markets’ unprecedented growth in 2021, with
            players such as BITTOKEN leaving their imprints on the ecosystem.
          </p>
        </li>
        <li>
          <p className="news-title">
            BITTOKEN Announces a Sponsorship Deal with PGL Major as its Official
            Partner
          </p>
          <p className="news-desc">
            BITTOKEN, a global crypto asset exchange, has recently announced to
            sponsor PGL Major Stockholm 2021 as its new official partner. Only
            last month, by sponsoring Italian soccer giants Juventus as its
            first-ever sleeve partner, BITTOKEN became a highly anticipated
            trading platform in the industry. The new partnership with PGL Major
            Stockholm 2021 reflects again the platform’s strategic vision to
            enter the global market through cross-sector cooperation.
          </p>
        </li>
      </ul>
    </div>
  );
});

export default News;
