import { useMutation, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import ModalBlock from "pages/Home/ModalBlock";
import ModalBonus from "pages/Home/ModalBonus";
import ModalCountTime from "pages/Trade/ModalCountTime";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useNotify } from "_common/component/NotifyProvider";
import { useStores } from "_common/hooks";
import { GameApi } from "states/api/game";
import i18next from "i18next";

import MobileFooter from "../Footer/MobileFooter";

const MobileLayout = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { notify, setNotify, dataGame, setDataGame } = useNotify();
  const {
    authStore: { user, setLoading },
  } = useStores();
  const [openModalNotify, setModalNotify] = useState(false);
  const [openModalBlock, setModalBlock] = useState(false);
  const [openModalCountTime, setModalCountTime] = useState(false);
  const [dataNotice, setDataNotice] = useState();

  const { mutate: onCheckResult } = useMutation(
    (variables) =>
      GameApi.checkResult({
        params: { ...variables },
      }),
    {
      onSuccess: (res, variables) => {
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-player", "list"]);
        if (res?.data?.status === "win" || res?.data?.status === "lose") {
          setDataNotice(res?.data);
          setModalNotify(true);
          setNotify();
        }
      },
      onError: (error) => {
        setModalNotify(true);
        setNotify();
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        // NotificationManager.error(errorMessage);
      },
    }
  );

  useEffect(() => {
    if (notify?.data?.data?.user_id === user?.id) {
      queryClient.invalidateQueries(["get_profile"]);
      setNotify();
    }
  }, [notify, user]);

  useEffect(() => {
    if (
      notify?.data?.type === "send_notice" &&
      notify?.data?.data?.user_id === user?.id
    ) {
      setDataNotice(notify?.data?.data);
      setModalNotify(true);
      setNotify();
    }
  }, [notify, user]);

  useEffect(() => {
    if (dataGame?.status === "start" && dataGame?.time > 0) {
      setModalCountTime(true);
    } else if (dataGame?.status === "finish") {
      setModalCountTime(false);
      setLoading(false);
      setDataGame({});
      onCheckResult({
        game_id: dataGame?.game_id,
        close_price: dataGame?.close_price,
      });
    }
  }, [dataGame]);

  useEffect(() => {
    if (!user?.status) {
      setModalBlock(true);
    } else {
      setModalBlock(false);
    }
  }, [user?.status]);

  return (
    <div className="tw-dark">
      <div className="mobile-layout font-['Roboto'] dark:text-gray-300 text-gray-900 w-full h-full">
        <div className="flex w-full h-full">
          <div
            className={classNames(
              "flex flex-col w-full h-screen max-h-full min-h-full transition-all desktop-body ml-0"
            )}
          >
            <Outlet />
          </div>
        </div>
      </div>
      {openModalNotify && (
        <ModalBonus
          open={openModalNotify}
          onClose={() => setModalNotify(false)}
          data={dataNotice}
        />
      )}
      {openModalCountTime && (
        <ModalCountTime
          data={dataGame}
          open={openModalCountTime}
          onClose={() => setModalCountTime(false)}
        />
      )}
    </div>
  );
});

export default MobileLayout;
