import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Portal } from "react-portal";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { observer } from "mobx-react-lite";
import vi from "assets/img/lang/vi.png";
import en from "assets/img/lang/en.png";
import code2 from "assets/img/lang/code2.png";
import code3 from "assets/img/lang/code3.png";
import code4 from "assets/img/lang/code4.png";
import code5 from "assets/img/lang/code5.png";
import code7 from "assets/img/lang/code7.png";
import code9 from "assets/img/lang/code9.png";
import code10 from "assets/img/lang/code10.png";
import code11 from "assets/img/lang/code11.png";

import SelectBox from "../SelectBox";

const optionsData = [
  {
    value: "vi",
    label: "Tiếng việt",
    image: vi,
  },
  {
    value: "en",
    label: "English",
    image: en,
  },
  {
    value: "code3",
    label: "Japanese",
    image: code3,
  },
  {
    value: "code4",
    label: "한국어",
    image: code4,
  },
  {
    value: "code9",
    label: "Español",
    image: code9,
  },
  {
    value: "code7",
    label: "Deutsch",
    image: code7,
  },
  {
    value: "code10",
    label: "Portugal",
    image: code10,
  },
  {
    value: "code11",
    label: "Русский язык",
    image: code11,
  },
  {
    value: "code2",
    label: "简体中文",
    image: code2,
  },
  {
    value: "code5",
    label: "繁體中文",
    image: code5,
  },
];

const SelectLanguage = observer(({ open, onClose }) => {
  const {
    authStore: { setLanguage, language },
  } = useStores();

  const handleChangeLanguage = (item) => {
    setLanguage(item?.value);
  };

  return (
    <SelectBox
      onChange={handleChangeLanguage}
      options={optionsData}
      defaultValue={optionsData.find((obj) => obj.value === language)}
    />
  );
});

export default SelectLanguage;
