import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import icon30 from "assets/img/icon/icon30.png";

function HeaderNav({
  title,
  position = "",
  isBack = true,
  theme = "dark",
  right,
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, game_wallet },
  } = useStores();

  const onGetProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  return (
    <div
      className={classNames("bg_header bg_header_nav", {
        bg_light: theme === "light",
      })}
    >
      <h2
        className={classNames("bg_header_name text-[18px]", {
          [position]: true,
        })}
      >
        {title}
      </h2>
      <div className="right_rf" onClick={() => window.location.reload()}>
        <img src={icon30} alt="" />
      </div>
    </div>
  );
}

export default HeaderNav;
