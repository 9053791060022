import React from "react";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

export default function Notice() {
  return (
    <div className="container introduce-page !mt-[50px]">
      <HeaderTop title="Complaint suggestion" />
    </div>
  );
}
