import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { useStores } from "_common/hooks";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import i18next from "i18next";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "_common/component/Loading";
import { observer } from "mobx-react-lite";
import InputPassword from "_common/component/InputPassword";

const schema = yup
  .object({
    username: yup.string().required(i18next.t("verify_require")),
    password: yup.string().required(i18next.t("verify_require")),
  })
  .required();

const LoginMobile = observer(() => {
  const navigate = useNavigate();
  const { authStore } = useStores();
  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.login(variables),
    {
      onSuccess: (res) => {
        authStore.updateUser(res?.data);
        setTimeout(() => {
          navigate("/");
        }, 200);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <>
      <div className="no_headers header_box ">
        <div
          className="fl bhalf allhg txtl"
          style={{ lineHeight: "50px", paddingLeft: "1.5rem" }}
        >
          Tiếng việt
        </div>
        <div
          className="fr bhalf allhg txtr"
          style={{ lineHeight: "55px", paddingRight: "1.5rem" }}
        >
          <FontAwesomeIcon
            icon={faClose}
            style={{ fontSize: "30px", color: "rgb(109, 109, 109)" }}
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
      <form className="auth-sign-in" onSubmit={handleSubmit(handleLogin)}>
        {isLoading && <Loading />}
        <div className="sbui-space-col sbui-space-y-6 pr-[1.5rem] pl-[1.5rem]">
          <h2 className="pb-[1.5rem]">Welcome Log in BITTOKEN</h2>
          <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive !mt-[0]">
            <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
              <div className="sbui-formlayout__label">
                {i18next.t("username")}
              </div>
            </div>
            <div className="sbui-formlayout__content-container-horizontal">
              <div className="sbui-input-container">
                <input
                  type="text"
                  className="sbui-input"
                  {...register("username")}
                />
              </div>
            </div>
          </div>
          <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
            <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
              <div className="sbui-formlayout__label">
                {i18next.t("password")}
              </div>
            </div>
            <div className="sbui-formlayout__content-container-horizontal">
              <div className="sbui-input-container">
                <InputPassword
                  autoComplete="current-password"
                  type="password"
                  className="sbui-input"
                  {...register("password")}
                />
              </div>
            </div>
          </div>
          <span className="sbui-btn-container sbui-btn--w-full">
            <button
              className="text-[#ffffff] font-[600] !bg-[#78c9eb] w-full min-h-[44px]"
              type="submit"
              disabled={!isValid || isLoading}
            >
              <span> {i18next.t("login")}</span>
            </button>
          </span>
          <div className="sbui-space-row flex justify-between sbui-space-x-2 pt-3 ">
            <p>{i18next.t("forgot_password")}</p>
            <Link to="/auth/register">{i18next.t("register_yet")}</Link>
          </div>
        </div>
      </form>
    </>
  );
});

export default LoginMobile;
