import React, { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { Portal } from "react-portal";
import SelectItem from "./SelectItem";
import { useMediaQuery } from "../MediaQueryProvider";

function SelectBox({ options = [], defaultValue, onChange, className }) {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const { isMobile } = useMediaQuery();
  const [selected, setSelected] = useState(defaultValue || options[0]);

  const handleClick = useCallback(
    (event) => {
      const { target } = event;
      if (open && !ref.current?.contains(target)) {
        setOpen(false);
      }
    },
    [open]
  );

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  const handleChange = (item) => {
    if (typeof onChange === "function") {
      onChange(item);
    }
    setSelected(item);
    setOpen(false);
  };

  return (
    <div className={classNames("sbui-select-container w-full", className)}>
      <div className="sbui-listbox-container">
        <div className="relative ">
          <span
            type="button"
            onClick={() => setOpen(!open)}
            className="flex justify-end"
          >
            <div className="flex items-center gap-[10px]">
              <img src={selected?.image} className="w-[20px] h-[20px]" alt="" />
              {!isMobile && <span>{selected?.label}</span>}
            </div>
          </span>
          {open && (
            <ul className="sbui-listbox-option-container" ref={ref}>
              {options?.map((item, index) => (
                <SelectItem
                  key={`option-${index?.toString()}`}
                  onChange={() => handleChange(item)}
                  isSelect={item?.value === selected?.value}
                  isDisable={item?.disabled}
                >
                  <div className="flex items-center gap-[10px]">
                    <img
                      src={item?.image}
                      className="w-[20px] h-[20px]"
                      alt=""
                    />
                    {item?.label}
                  </div>
                </SelectItem>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default SelectBox;
