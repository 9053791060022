import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { useStores } from "_common/hooks";
import i18next from "i18next";

function ModalPrivacyPolicy({ open, onClose }) {
  const {
    authStore: { general },
  } = useStores();

  return (
    <Portal>
      <div
        className="modal modal-full"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="mask" />
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="font-bold">Privacy Policy</h2>
            <div className="btn-close" onClick={() => onClose()}>
              X
            </div>
          </div>
          <div className="classic-bet-now">
            <div className="content">
              <div className="rules__content">
                <p>
                  {`("BITTOKEN", "we" or "our") is committed to protecting and
                  respecting the privacy of all users ("users" or "you") who use
                  our platform, products, and services.`}
                </p>
                <p>
                  <br />
                </p>
                <p>
                  {`This privacy policy (this "Privacy Policy"), together with the
                  terms of use, governs our collection, retention, processing,
                  sharing and use of your personal information. "Personal
                  Information" means information that identifies you personally,
                  including but not limited to: your name, address, email
                  address and transactions.`}
                </p>
                <p>
                  <br />
                </p>
                <p>
                  Unless otherwise stated or defined in this Privacy Policy, any
                  terms shall have the same meaning as provided in the Terms of
                  Use.
                </p>
                <p>
                  <br />
                </p>
                <p>The purpose of this Privacy Policy is to inform you of:</p>
                <p>
                  <br />
                </p>
                <p>(1) Who is BITTOKEN and how do you contact us;</p>
                <p>
                  <br />
                </p>
                <p>
                  {`(2) the types of your personal information that we may
                  collect, the reasons for collecting the information, how we
                  may use the information and the length of time we will retain
                  it;`}
                </p>
                <p>
                  <br />
                </p>
                <p>
                  {`(3) our use of information about internal protocol addresses
                  ("IP addresses") and our use of cookies;`}
                </p>
                <p>
                  <br />
                </p>
                <p>
                  (4) any disclosure of your personal information to third
                  parties;
                </p>
                <p>
                  <br />
                </p>
                <p>
                  (5) Your right to access, correct, update, restrict use,
                  request us to transfer and/or delete your personal
                  information;
                </p>
                <p>
                  <br />
                </p>
                <p>
                  (6) the extent to which we use your personal information for
                  automated decision-making or analysis;
                </p>
                <p>
                  <br />
                </p>
                <p>
                  (7) security measures to prevent the loss, misuse or
                  alteration of personal information under our control; and
                </p>
                <p>
                  <br />
                </p>
                <p>(8) Your right to lodge a complaint.</p>
                <p>
                  <br />
                </p>
                <p>
                  We provide cross-platform trading through our website and
                  mobile application, providing highly liquid orders for
                  cryptocurrency contracts for mainstream cryptocurrencies in
                  the market.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalPrivacyPolicy;
