import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { useStores } from "_common/hooks";
import i18next from "i18next";

function ModalTerm({ open, onClose }) {
  const {
    authStore: { general },
  } = useStores();

  return (
    <Portal>
      <div
        className="modal modal-full"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="mask" />
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="font-bold">Terms agreement</h2>
            <div className="btn-close" onClick={() => onClose()}>
              X
            </div>
          </div>
          <div className="classic-bet-now">
            <div className="content">
              <div>
                <p>
                  <span>
                    {`The terms of use contain important terms, and users must
                    carefully consider when choosing whether to visit this
                    website, launch an application or use any service. Before
                    agreeing to the terms of use, please read these terms of use
                    (including the policies contained therein). Users are solely
                    responsible for understanding and complying with their
                    residence, nationality, registration, establishment,
                    location when accessing and using this platform, and /Or any
                    or all laws, rules and regulations of any jurisdiction
                    applicable to the use of any or all services on this
                    platform ("Law").`}
                  </span>
                </p>
                <p>
                  <span>
                    {`The terms of use include privacy policies, AML/KYC policies,
                    and any other policies ("other policies") published on
                    applications or websites or provided to you by other means
                    ("other policies"). The user accepts the terms of use, which
                    means that you agree and accept privacy Policies, AML/KYC
                    policies and all other policies.`}
                  </span>
                </p>
                <p>
                  <span>
                    Users should carefully read all terms of use before
                    accessing the platform or using any service.
                  </span>
                </p>
                <p>
                  <span>
                    By accessing the platform and/or using any service, the user
                    agrees to accept and abide by the terms of use. If the user
                    does not agree or no longer agrees to accept and abide by
                    the terms of use, the user should immediately log out of the
                    platform, and may not use or stop using the service
                    immediately.
                  </span>
                </p>
                <p>
                  <span>1. Interpretation</span>
                </p>
                <p>
                  <span>
                    1.1. The vocabulary used in the terms of use shall be
                    explained in accordance with the following definitions.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.1. Account refers to the account registered by the user
                    on the platform.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.2. Application refers to any mobile application
                    developed and provided by BITTOKEN for users to access
                    accounts and use services.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.3. The base cryptocurrency refers to the first symbol in
                    the transaction pairing.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.4. Buyer refers to any user who submits an order to
                    purchase a cryptocurrency contract through the platform, in
                    which the user copies a long position in the base
                    cryptocurrency and obtains benefits from all price increases
                    of the base cryptocurrency (correspondingly, it also bears
                    the cost of all the base cryptocurrencies). The loss of all
                    price drops).
                  </span>
                </p>
                <p>
                  <span>
                    1.1.5. The seller refers to any user who submits an order to
                    sell a cryptocurrency contract through the platform, in
                    which the user copies the short position in the base
                    cryptocurrency and obtains benefits from all the price drops
                    of the base cryptocurrency (correspondingly, it also bears
                    the cost of all the base cryptocurrencies). The loss of all
                    price increases).
                  </span>
                </p>
                <p>
                  <span>
                    1.1.6. Cryptocurrency contract refers to a transaction
                    contract for a certain cryptocurrency provided by the
                    platform from time to time.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.7. Cryptocurrency refers to the point-to-point
                    decentralized digital value manifestation, including but not
                    limited to BITTOKEN and Ether.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.8. Tether refers to Tether (Tether), a stable currency
                    issued by Tether Limited. The company claims that each
                    issued token is pegged to one dollar one to one; it is also
                    designed as a value of Tether. Always one dollar.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.9. Deposit transactions refer to transactions involving
                    the transfer of TEDA coins into an account.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.10. Margin trading refers to leveraged transactions.
                    Users can use borrowed TEDA coins instead of the user"s own
                    TEDA coins to trade (meaning that the position opened using
                    the user"s own TEDA coins is less than the actual amount
                    required for the position ).
                  </span>
                </p>
                <p>
                  <span>
                    1.1.11. An order refers to a user"s instruction to buy or
                    sell a cryptocurrency contract under specified conditions.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.12. The platform refers to the environment created by
                    BITTOKEN that allows cryptocurrency contract transactions,
                    including applications and websites.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.13. Services refer to all and any services provided by
                    BITTOKEN, including cryptocurrency contract trading services
                    designed, managed, and operated by BITTOKEN, but excluding
                    any over-the-counter transaction service provider as stated
                    in 2.4. Foreign transaction services.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.14. This website refers to the website of BITTOKEN
                    https://okx-us.net.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.15. Overnight fee refers to any service fee charged by
                    BITTOKEN in order to allow users to reserve positions until
                    the next trading day. (The starting time of each trading day
                    is 6:00 AM Singapore time (UTC +8))
                  </span>
                </p>
                <p>
                  <span>
                    1.1.16. Commission refers to each fee payable to BITTOKEN
                    for each completed transaction.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.17. Transaction price refers to the price paid by the
                    buyer or seller for each transaction conducted through the
                    service.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.18. Transaction refers to (i) the user transfers TEDA
                    coins to the user account ("deposit transaction"), (ii) the
                    creation of any cryptocurrency contract between users
                    ("trade transaction"), or (iii) any withdrawal trade.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.19. User refers to any individual or entity that uses
                    the service, agrees to abide by the terms of use, and is the
                    account holder.
                  </span>
                </p>
                <p>
                  <span>
                    1.1.20. Withdrawal transaction refers to any transaction
                    involving the transfer of TEDA coins from a user"s account
                    to an account opened by the user in any other institution.
                    Such transfer will be calculated with reference to the
                    guidelines determined and issued by BITTOKEN from time to
                    time.
                  </span>
                </p>
                <p>
                  <span>
                    1.2 Where the context permits, the singular word shall
                    include its plural form, and vice versa.
                  </span>
                </p>
                <p>
                  <span>2. Scope of service</span>
                </p>
                <p>
                  <span>
                    2.1. The service allows all users on the platform to conduct
                    cryptocurrency contract transactions on the platform.
                  </span>
                </p>
                <p>
                  <span>
                    2.2. Depending on the user"s place of residence, some users
                    may not be able to use some or all of the functions provided
                    by the platform. The user bears all responsibility for
                    compliance with the law.
                  </span>
                </p>
                <p>
                  <span>
                    2.3. The user acknowledges and agrees that when completing
                    any transaction, BITTOKEN may: (i) limit the position
                    difference of cryptocurrency contract orders on the platform
                    at any time; (ii) hedge the position difference of
                    cryptocurrency contract orders; (iii) The aforementioned
                    hedging of orders on other cryptocurrency platforms around
                    the world; each of the above situations will be determined
                    by BITTOKEN at its sole discretion ("hedging operations").
                  </span>
                </p>
                <p>
                  <span>
                    2.4. For the avoidance of doubt, any service ("OTC")
                    provided by any third-party service provider for any
                    over-the-counter (OTC, "OTC") business shall not be
                    considered It is part of the service scope of the platform,
                    and any over-the-counter service provider shall not be
                    regarded as a party to these terms of use or any third-party
                    interest holder.
                  </span>
                </p>
                <p>
                  <span>3. User"s rights and responsibilities</span>
                </p>
                <p>
                  <span>
                    3.1. The user has the right to access the platform and use
                    the service, provided that the user has agreed to abide by
                    and actually abide by the terms of use.
                  </span>
                </p>
                <p>
                  <span>
                    3.2. The user promises to abide by any and all applicable
                    laws related to the use of the service.
                  </span>
                </p>
                <p>
                  <span>
                    3.3. The user undertakes to monitor all and any changes to
                    the user account, including but not limited to the account
                    balance.
                  </span>
                </p>
                <p>
                  <span>
                    3.4. The user promises to notify BITTOKEN immediately (as
                    soon as it is discovered) when any unusual, suspicious,
                    unknown or abnormal changes in the user account are
                    discovered. If the user delays the notification or fails to
                    notify the user, the user will have to violate the terms of
                    use. Take responsibility and BITTOKEN has the right to take
                    any corresponding actions (including but not limited to
                    reporting to relevant regional or national agencies).
                  </span>
                </p>
                <p>
                  <span>
                    3.5. The user agrees that whenever a transaction is made,
                    the platform can send and receive TEDA coins to/from the
                    accounts opened by the buyer and seller in the user"s name
                    and on behalf of the user.
                  </span>
                </p>
                <p>
                  <span>
                    3.6. The user promises to notify BITTOKEN of any
                    unauthorized use of user accounts or passwords or any other
                    security breaches immediately by sending an email to
                    info@BITTOKEN.com. Any service provided to users who violate
                    Article 3.6 may be terminated, and the user may be liable
                    for any losses suffered by BITTOKEN or any other users of
                    this website or this application.
                  </span>
                </p>
                <p>
                  <span>
                    3.7. The user promises not to use the service to conduct any
                    form of criminal activities, including but not limited to
                    money laundering, illegal gambling activities, malicious
                    hacking, or funding terrorist organizations or activities.
                  </span>
                </p>
                <p>
                  <span>
                    3.8. The user shall be liable for all damages caused by
                    BITTOKEN"s infringement of any third party"s rights or
                    violation of any applicable law due to the user"s access to
                    the platform or use of any service, and all claims and
                    litigation filed against BITTOKEN, and shall compensate
                    BITTOKEN.
                  </span>
                </p>
                <p>
                  <span>
                    3.9. The user is solely responsible for determining whether
                    any proposed transaction is suitable for the user based on
                    the user"s personal goals, financial status and risk
                    willingness.
                  </span>
                </p>
                <p>
                  <span>4. User declarations, guarantees and promises</span>
                </p>
                <p>
                  <span>
                    4.1. The user hereby expressly declares, guarantees and
                    promises:
                  </span>
                </p>
                <p>
                  <span>
                    (i) The user has followed and will follow the law.
                  </span>
                </p>
                <p>
                  <span>
                    (ii) If the user is a natural person, he must be at least 18
                    years old (or have reached the age recognized by applicable
                    laws as an adult); if the user is an entity, the user must
                    be formally established under the laws of the place where it
                    was established, validly exist, and have a good reputation
                    Entity, and it has the right and authority to hold, lease
                    and operate its property and continue its business.
                  </span>
                </p>
                <p>
                  <span>
                    (iii) The user has the rights, powers, capabilities,
                    qualifications, authorizations, approvals, permits, filing,
                    knowledge and experience required to participate and
                    complete transactions at least within the scope of
                    applicable legal requirements.
                  </span>
                </p>
                <p>
                  <span>
                    (iv) The user"s execution, delivery and performance of these
                    terms of use and participation and completion of
                    transactions shall be within the scope of his power or
                    authorization, and shall have obtained formal authorization
                    for all necessary actions (if the user is an entity). These
                    terms of use constitute legal, effective and binding
                    obligations to users, which can be enforced according to
                    user terms, such as bankruptcy, insolvency or other general
                    applicable laws and general fairness that involve or affect
                    the compulsory exercise of power by creditors Except in
                    principle.
                  </span>
                </p>
                <p>
                  <span>
                    (v) The user’s execution, delivery and performance of these
                    terms of use and participation and completion of
                    transactions have not and will not violate (i) its articles
                    of association, by-laws and other articles of association
                    (if the user is an entity), (ii) applicable to the user’s
                    Any laws, rules, regulations, judgments, administrative
                    orders or decisions, or (iii) any important contracts,
                    documents or other documents that the company is a party to
                    or bound by.
                  </span>
                </p>
                <p>
                  <span>
                    (vi) If the user is an entity, the user does not need to
                    obtain consent or approval for its execution, delivery and
                    performance of these terms of use and participation and
                    completion of transactions (except for internal user
                    approval).
                  </span>
                </p>
                <p>
                  <span>
                    4.2. The user promises to only access the platform and/or
                    use the service to participate in and complete transactions
                    in accordance with the conditions specified in the terms of
                    use.
                  </span>
                </p>
                <p>
                  <span>
                    4.3. The user"s statement, guarantee and promise that any
                    amount of TEDA deposits deposited in the account belongs to
                    the user and the source is legal. Upon request of BITTOKEN,
                    users should immediately provide any necessary evidence to
                    prove the source of funds.
                  </span>
                </p>
                <p>
                  <span>
                    4.4. The user promises to only withdraw any amount of TEDA
                    from the user"s account to the user"s TEDA wallet.
                  </span>
                </p>
                <p>
                  <span>
                    4.5. The user declares, guarantees and promises that all
                    transactions shall be conducted using user accounts and own
                    funds, and users will not be entrusted or accept commissions
                    from others to conduct any transactions on the platform on
                    behalf of any other person.
                  </span>
                </p>
                <p>
                  <span>
                    4.6. The user agrees that for reasons of legal obligations
                    (including but not limited to crime prevention, tax purposes
                    and/or providing services requested by the user), the user"s
                    personal information and identity can be shared with
                    appropriately authorized third parties.
                  </span>
                </p>
                <p>
                  <span>
                    4.7. The user acknowledges and agrees that once BITTOKEN
                    receives an order to buy or sell cryptocurrency contracts,
                    such orders may not be cancelled or withdrawn, and BITTOKEN
                    may hold all cryptocurrency contracts obtained by each user
                    in the user account on behalf of each user .
                  </span>
                </p>
                <p>
                  <span>
                    4.8. The user acknowledges and agrees that BITTOKEN shall
                    not be liable for any loss or liability caused by or related
                    to any behavior or negligence of any marketing agency or
                    individual who is not an employee of BITTOKEN and does not
                    constitute its agent.
                  </span>
                </p>
                <p>
                  <span>5. Rights and responsibilities of BITTOKEN</span>
                </p>
                <p>
                  <span>
                    5.1. BITTOKEN has the right to suspend the user"s account
                    and/or freeze any funds in the user"s failure to perform or
                    improperly perform any of these terms of use, provided that
                    BITTOKEN must immediately notify the user of the reasons for
                    the suspension and/or freezing.
                  </span>
                </p>
                <p>
                  <span>
                    5.2. BITTOKEN promises to provide services with commercially
                    reasonable efforts and caution.
                  </span>
                </p>
                <p>
                  <span>
                    5.3. To the extent permitted by law, BITTOKEN is not liable
                    for any profit or loss, loss of income, loss of business,
                    loss of opportunity, loss of data, or indirect, special,
                    exemplary, consequential or punitive damages.
                  </span>
                </p>
                <p>
                  <span>
                    5.4. BITTOKEN is not responsible for any failure, failure,
                    delay or interruption of the Internet connection or the
                    unavailability of the platform at any particular time for
                    any reason.
                  </span>
                </p>
                <p>
                  <span>
                    5.5. BITTOKEN is not responsible for payment processing
                    delays caused by the fault of any third party or operator in
                    the transaction.
                  </span>
                </p>
                <p>
                  <span>
                    5.6. In the event of any fraud or non-compliance with the
                    law, BITTOKEN may report all relevant information, including
                    the user"s name, address and all other required information,
                    to the relevant agencies dealing with such fraud or
                    violations. The user acknowledges that the user"s account
                    can be frozen at any time when required by any competent
                    authority to investigate fraud or any other illegal
                    activities.
                  </span>
                </p>
                <p>
                  <span>
                    5.7. Nothing in these terms of use excludes or restricts
                    TCEHK’s liability for fraud, death, or personal injury
                    caused by its negligence or violation of these terms of use,
                    as implied by the implementation of the law, or any other
                    that cannot be restricted or restricted by law. Liability
                    that cannot be excluded.
                  </span>
                </p>
                <p>
                  <span>6. BITTOKEN Statement, Guarantee and Commitment</span>
                </p>
                <p>
                  <span>
                    6.1. All purchase and sale orders made on the platform will
                    be managed anonymously, so the buyer and seller do not know
                    each other"s identities.
                  </span>
                </p>
                <p>
                  <span>
                    6.2. BITTOKEN will decide on its own whether to conduct
                    hedging operations.
                  </span>
                </p>
                <p>
                  <span>
                    6.3. The transaction price is calculated on a weighted
                    average basis based on the actual hedged orders in the
                    hedging operation.
                  </span>
                </p>
                <p>
                  <span>
                    6.4. In extreme cases, including but not limited to any
                    irregularities or crashes in the cryptocurrency market,
                    BITTOKEN may stop any transaction order (whether executed or
                    hedged) at its sole discretion, or make any user decide on
                    BITTOKEN During any period of time, no buy and sell orders
                    can be made on the platform.
                  </span>
                </p>
                <p>
                  <span>
                    6.5. In view of the relevant characteristics of the platform
                    and cryptocurrency contracts, the platform can take any
                    necessary actions to ensure the stability of the platform,
                    including but not limited to restricting users" rights to
                    use their own accounts or trade any cryptocurrency
                    contracts. Such matters are determined by BITTOKEN"s sole
                    discretion.
                  </span>
                </p>
                <p>
                  <span>
                    6.6. In view of the characteristics of the cryptocurrency
                    market, in order to ensure the interests of the platform and
                    users, the platform may reduce or increase the types of
                    cryptocurrencies that can submit cryptocurrency contracts
                    for trading on the platform at its sole discretion; when the
                    cryptocurrency under the user"s existing cryptocurrency
                    contract is not When supported again, the platform will no
                    longer allow these cryptocurrency contracts to hold
                    positions overnight.
                  </span>
                </p>
                <p>
                  <span>7. Intellectual Property</span>
                </p>
                <p>
                  <span>
                    7.1. Unless otherwise stated in these terms of use, all
                    content on the platform is the property of BITTOKEN and is
                    protected by copyright, patents, trademarks, trade secrets
                    and any other applicable laws.
                  </span>
                </p>
                <p>
                  <span>
                    7.2. Trademarks, product names, service marks and logos, and
                    other content ("trademarks") used on the platform are the
                    property of BITTOKEN and their respective owners. The
                    software, applications, text, images, graphics, data,
                    prices, transactions, charts, drawings, and video and audio
                    materials used on the platform are the property of BITTOKEN.
                    Trademarks and other content on the platform shall not be
                    copied, copied, modified, republished, uploaded, published,
                    disseminated, deleted, collected or distributed (whether
                    manually or automatically) in any form or by any means. In
                    addition to participating in and completing transactions, it
                    is strictly forbidden to use any content of the platform on
                    any other website or networked computer environment for any
                    purpose; and any such unauthorized use may violate
                    BITTOKEN"s copyright, patent, trademark and any Other
                    applicable laws and may result in criminal or civil
                    penalties. Users shall not copy, modify, reverse engineer or
                    attempt to extract the source code of any software used by
                    BITTOKEN when setting up the platform and/or providing
                    services.
                  </span>
                </p>
                <p>
                  <span>
                    7.3. BITTOKEN supports open source software. Part of the
                    software used in the platform and services provided by
                    BITTOKEN to users may be provided under an open source
                    software license. The relevant clauses in such open source
                    software licenses may be more effective than these terms of
                    use.
                  </span>
                </p>
                <p>
                  <span>
                    7.4. BITTOKEN supports intellectual property protection. If
                    you intend to submit (i) any trademark claim that violates
                    your valid, registered trademark or service mark; (ii) a
                    copyright claim against any material for which you have
                    genuine copyright, or (iii) any other intellectual property
                    claims such as patent infringement , Please send an email to
                    info@BITTOKEN.com.
                  </span>
                </p>
                <p>
                  <span>8. User verification</span>
                </p>
                <p>
                  <span>
                    8.1. All transactions require identification and
                    verification procedures ("Know Your Customer" or "KYC"). All
                    existing users and new users of the platform must pass a
                    verification procedure. BITTOKEN reserves the right to
                    restrict the platform functions of users who have not passed
                    the verification program. If the user refuses to provide the
                    documents and information required by KYC, BITTOKEN reserves
                    the right to immediately terminate the provision of services
                    to the user.
                  </span>
                </p>
                <p>
                  <span>
                    8.2. The user promises to provide BITTOKEN with relevant
                    documents containing correct personal information. If the
                    user provides forged documents and false personal
                    information, BITTOKEN can suspend, cancel or terminate the
                    user"s account and freeze the funds it deems appropriate.
                  </span>
                </p>
                <p>
                  <span>
                    8.3. The user hereby authorizes BITTOKEN to directly or
                    indirectly (through a third party) conduct any inquiry it
                    deems necessary to verify the relevance and accuracy of the
                    information provided for verification purposes. The scope of
                    the personal data to be transferred will be limited to the
                    necessary evidence, and security measures will be taken to
                    protect the data.
                  </span>
                </p>
                <p>
                  <span>
                    8.4. The user hereby agrees to abide by the effective
                    anti-money laundering (AML) policy and Know Your Customer
                    (KYC) policy issued by BITTOKEN from time to time.
                  </span>
                </p>
                <p>
                  <span>9. Account maintenance</span>
                </p>
                <p>
                  <span>
                    9.1. This platform is only for users" personal and
                    non-commercial use. BITTOKEN is always vigilant in
                    maintaining the security of the platform and services. By
                    accessing the platform or using the service, the user agrees
                    to provide BITTOKEN with current, accurate and complete
                    personal information according to the prompts in the
                    registration process, and is obliged to continuously update
                    the relevant information.
                  </span>
                </p>
                <p>
                  <span>
                    9.2. The user is only allowed to register one account on the
                    platform. Therefore, any additional accounts may be
                    suspended.
                  </span>
                </p>
                <p>
                  <span>
                    9.3. The user agrees that it will not use any account other
                    than the user"s own account, nor will it access any other
                    user"s account at any time or assist others in obtaining
                    unauthorized access or conducting any related transaction
                    activities.
                  </span>
                </p>
                <p>
                  <span>
                    9.4. BITTOKEN reserves the right to set an amount limit for
                    account deposits/withdrawals/balances. BITTOKEN can also set
                    daily, monthly and per transaction amount limits.
                  </span>
                </p>
                <p>
                  <span>
                    9.5. The user is responsible for keeping the user account
                    information confidential, including but not limited to the
                    user"s password, email, wallet address, wallet balance, and
                    all activities including transactions conducted through his
                    account. If there is any suspicious activity related to the
                    user"s account, BITTOKEN may ask the user to provide
                    additional information, including verification documents,
                    and may suspend the account and/or freeze the funds during
                    the review period. The user hereby agrees to comply with the
                    security requirements or accept the suspension and freezing.
                  </span>
                </p>
                <p>
                  <span>
                    9.6. In any case where BITTOKEN"s prior express permission
                    is not obtained, the creation or use of accounts will cause
                    all such accounts and user accounts as well as all pending
                    buy/sell orders issued from these accounts to be immediately
                    suspended. Any attempt to do so or assist others (users or
                    other third parties) to do so, or any instructions, software
                    or tools issued for this purpose will result in the
                    termination of unauthorized accounts and user accounts.
                    Termination is not the only remedy for such violations, and
                    BITTOKEN may decide to take further action against users.
                  </span>
                </p>
                <p>
                  <span>10. Transaction</span>
                </p>
                <p>
                  <span>
                    10.1. The platform allows users to submit orders to buy or
                    sell cryptocurrency contracts.
                  </span>
                </p>
                <p>
                  <span>
                    10.2. The user agrees that the order should only be
                    submitted after careful consideration, and the user
                    understands and accepts the consequences of its execution.
                    The user agrees that once the order is executed, such
                    transactions will be irreversible and cannot be cancelled.
                  </span>
                </p>
                <p>
                  <span>
                    10.3. The user acknowledges and agrees that if the
                    destination tag is not specified or displayed incorrectly
                    (especially XRP) or the reference number is specified
                    incorrectly (for TEDA currency transactions), the user may
                    lose the deposit or the processing speed of the related
                    request will be lower In order of priority.
                  </span>
                </p>
                <p>
                  <span>
                    10.4. The minimum order amount is 10 TEDA and the maximum
                    order amount is 20,000 TEDA. BITTOKEN reserves the right to
                    change the minimum and maximum order amounts, and the
                    relevant information will be displayed on the transaction
                    page when the order is placed.
                  </span>
                </p>
                <p>
                  <span>
                    10.5. The user acknowledges and agrees that due to certain
                    over-the-counter transaction verification procedures and the
                    inherent nature of the cryptocurrency network, the deposit
                    and withdrawal transactions of TEDA coins may be delayed.
                  </span>
                </p>
                <p>
                  <span>
                    10.6. If the user finds suspicious transactions or
                    activities (including but not limited to unknown deposits
                    and withdrawals) in the user account that are not initiated
                    by the user, he should immediately notify BITTOKEN of the
                    relevant facts and follow the instructions issued by
                    BITTOKEN. Otherwise, BITTOKEN reserves the right to suspend
                    the account and freeze the funds in it until the end of the
                    investigation.
                  </span>
                </p>
                <p>
                  <span>
                    10.7. BITTOKEN can cancel or recall any executed withdrawal
                    transaction at the request of any financial institution
                    (including but not limited to over-the-counter trading
                    institutions). In this case, the user is obliged to
                    cooperate with BITTOKEN to find out the reason for the
                    request.
                  </span>
                </p>
                <p>
                  <span>
                    10.8. The minimum deposit amount for TEDA coins is 200 TEDA
                    coins. If the amount is less than the specified amount, the
                    funds will not be credited to the user"s account.
                  </span>
                </p>
                <p>
                  <span>11. Margin trading</span>
                </p>
                <p>
                  <span>
                    11.1. Margin trading is applicable to long positions and
                    short positions, depending on the specific fees, limits and
                    conditions specified by BITTOKEN. ..
                  </span>
                </p>
                <p>
                  <span>
                    11.2. BITTOKEN may charge fees for opening and/or closing
                    positions and overnight fees.
                  </span>
                </p>
                <p>
                  <span>
                    11.3. The user acknowledges and agrees that, in order to
                    prevent the account balance from becoming a negative number,
                    when the price of any underlying cryptocurrency reaches the
                    "stop loss price" value specified when the user opens a
                    position, BITTOKEN has the right to close any position
                    regardless of whether the user is notified.
                  </span>
                </p>
                <p>
                  <span>
                    11.4. The user acknowledges and agrees that, in order to
                    lock in its profits, when the price of the underlying
                    cryptocurrency is close to the "Take Profit Price" value
                    specified when the user opens a position, BITTOKEN has the
                    right to close any position regardless of whether the user
                    is notified or not. The user further acknowledges and agrees
                    that the highest take-profit price ("Maximum Take-Profit
                    Price") set by any user when opening any position will be
                    subject to the maximum take-profit price limit set by
                    BITTOKEN from time to time.
                  </span>
                </p>
                <p>
                  <span>
                    11.5. If the user does not have sufficient funds to pay the
                    relevant fees, BITTOKEN reserves the right to close any of
                    its positions without prior notice to the user.
                  </span>
                </p>
                <p>
                  <span>
                    11.6. The user acknowledges and agrees that due to including
                    but not limited to the instant changes in the cryptocurrency
                    contract market and the discontinuity of the K-line chart,
                    the closing price displayed on the platform when the user is
                    about to close the position may be slightly different from
                    the actual closing price of the user. .
                  </span>
                </p>
                <p>
                  <span>12. Overnight fee</span>
                </p>
                <p>
                  <span>
                    12.1. If the user does not choose to open the "hold position
                    overnight" option, any cryptocurrency contract will be
                    closed at 6 a.m. Singapore time (UTC +8) (the "closing
                    time").
                  </span>
                </p>
                <p>
                  <span>
                    12.2. If the user chooses to open the "hold position
                    overnight" option, an overnight fee will be paid to
                    BITTOKEN.
                  </span>
                </p>
                <p>
                  <span>
                    12.3. The overnight fee will be charged daily at 30% of the
                    commission when the user opens a position.
                  </span>
                </p>
                <p>
                  <span>
                    12.4. The overnight fee will be directly deducted from the
                    TEDA balance in the user"s account. If the balance of
                    TEDAcoin is lower than the overnight fee to be deducted,
                    BITTOKEN will automatically deactivate the "hold position
                    overnight" option, and the position will be closed at the
                    closing time of the day. Any overnight fees charged from the
                    user account are non-refundable.
                  </span>
                </p>
                <p>
                  <span>
                    12.5. BITTOKEN reserves the right to change the amount and
                    charging method of the overnight fee.
                  </span>
                </p>
                <p>
                  <span>13. Commission</span>
                </p>
                <p>
                  <span>
                    13.1. The user agrees to pay a commission to BITTOKEN for
                    each completed transaction.
                  </span>
                </p>
                <p>
                  <span>
                    13.2. Before the transaction, the user must consider the
                    commission rate announced by the platform on the website,
                    application and the terms of use. However, BITTOKEN reserves
                    the right to change the commission rate from time to time
                    and will update the website, application and the terms of
                    use accordingly.
                  </span>
                </p>
                <p>
                  <span>
                    13.3. When the user opens a position, the commission amount
                    will be automatically deducted from the deposit in the form
                    of TEDA coins. The user is responsible for ensuring that the
                    account has sufficient funds for margin trading and payment
                    of commissions when opening a position. Unless the user has
                    sufficient funds for margin trading and payment of
                    commissions, cryptocurrency contract orders will not be
                    executed.
                  </span>
                </p>
                <p>
                  <span>
                    13.4. Commissions are charged based on each order
                    (cryptocurrency contract) according to the following
                    formula: commission = margin trading * (leverage multiple
                    -1) * commission rate. For example, if the user chooses to
                    open a long or short position with a commission rate of
                    0.15% by using 100 TEDA and 10 times leverage, the user
                    needs to pay 3.6 TEDA (100 TEDA*(10-1)* 0.15%). BITTOKEN can
                    decide to set and update the available leverage multiples
                    and corresponding commission rates from time to time at its
                    sole discretion.
                  </span>
                </p>
                <p>
                  <span>
                    13.5. BITTOKEN may at its sole discretion change or review
                    commissions, other fees and charging procedures from time to
                    time, and such changes will take effect as soon as they are
                    posted on the website and application.
                  </span>
                </p>
                <p>
                  <span>14. Rounding policy</span>
                </p>
                <p>
                  <span>
                    14.1. For all financial calculations, BITTOKEN may use
                    rounding policies that are beneficial to the platform.
                    BITTOKEN rounds TEDA to the second digit after the
                    separator. The rounding policy of other cryptocurrencies
                    depends on different cryptocurrencies.
                  </span>
                </p>
                <p>
                  <span>
                    14.2. For the purpose of process optimization, transactions
                    on certain currencies (including but not limited to TEDA and
                    other cryptocurrencies) on the platform will not be
                    reflected in user accounts, but as long as a unit as a whole
                    meets the rounding standard, it will It is immediately
                    displayed in the user account.
                  </span>
                </p>
                <p>
                  <span>15. Illegal transactions</span>
                </p>
                <p>
                  <span>
                    15.1. If BITTOKEN believes that it is required by law or to
                    comply with the advice provided by relevant government
                    agencies or authorized institutions on preventing financial
                    crimes, BITTOKEN reserves the right to suspend or terminate
                    user accounts and/or freeze funds in them at any time.
                  </span>
                </p>
                <p>
                  <span>
                    15.2. It is strictly forbidden for the user to use the
                    account for any illegal purpose. BITTOKEN has the right to
                    report any suspicious activities to relevant administrative,
                    regulatory or law enforcement agencies.
                  </span>
                </p>
                <p>
                  <span>
                    15.3. Users shall not use accounts or services for any
                    transactions related to the following matters:
                  </span>
                </p>
                <p>
                  <span>
                    (i) Money laundering, financing of terrorism, and
                    proliferation of weapons of mass destruction;
                  </span>
                </p>
                <p>
                  <span>(ii) Human trafficking;</span>
                </p>
                <p>
                  <span>
                    (iii) Any illegal goods or services, or illegal promotion,
                    sale and marketing of illegal goods or services, or contain
                    illegal, obscene or pornographic content, sexual gestures
                    involving children and minors, beautify war, or infringe
                    upon human dignity, or portray publicity methods or
                    unconstitutional Goods or services marked by the
                    organization;
                  </span>
                </p>
                <p>
                  <span>
                    (iv) Any product or service and its promotion, sale or
                    marketing that may infringe copyright, industrial property
                    rights or any person"s other rights;
                  </span>
                </p>
                <p>
                  <span>(v) Archaeological discoveries;</span>
                </p>
                <p>
                  <span>(vi) Drugs, anesthetics or hallucinogens;</span>
                </p>
                <p>
                  <span>(vii) Weapons of any type;</span>
                </p>
                <p>
                  <span>(viii) Illegal gambling services;</span>
                </p>
                <p>
                  <span>
                    (ix) Ponzi schemes, pyramids or any other "get rich quick"
                    schemes;
                  </span>
                </p>
                <p>
                  <span>(x) Goods subject to any trade embargo;</span>
                </p>
                <p>
                  <span>
                    (xi) Public opinion that is harmful to minors and violates
                    the law, especially violations of regulations on the
                    protection of minors;
                  </span>
                </p>
                <p>
                  <span>(xii) Body parts or human remains;</span>
                </p>
                <p>
                  <span>(xiii) Protected animals or protected plants;</span>
                </p>
                <p>
                  <span>
                    (xiv) weapons or explosives; or (xv) any other illegal
                    goods, services or transactions;
                  </span>
                </p>
                <p>
                  <span>16. Account security</span>
                </p>
                <p>
                  <span>
                    16.1. The user is responsible for keeping the credentials of
                    the user account confidential, including but not limited to
                    passwords, emails, wallet addresses, balances, and all
                    activities including through the account, including
                    transactions.
                  </span>
                </p>
                <p>
                  <span>
                    16.2. BITTOKEN personnel will never ask any user to disclose
                    the user"s password. Any information received by the user or
                    the website accessed by the user requesting the user
                    password (except this website and application), the user
                    should report this to BITTOKEN.
                  </span>
                </p>
                <p>
                  <span>
                    16.3. It is recommended to change the user password
                    regularly (at least every three (3) to six (6) months) to
                    reduce the risk of security vulnerabilities related to the
                    account. BITTOKEN also recommends that users do not choose
                    passwords or passwords that are easy to guess by someone who
                    knows the user or collects user information. Users should
                    not allow anyone to access or spy on users to access their
                    accounts.
                  </span>
                </p>
                <p>
                  <span>
                    16.4. If the user has any security concerns about the user
                    account, login details, password, or other security
                    functions are stolen, stolen, used without authorization, or
                    otherwise compromised, it is recommended that the user
                    change the password. Once the user finds the account, login
                    details, password loss, theft, theft or unauthorized use, or
                    other security issues, he must contact the support service
                    of BITTOKEN without unreasonable delay. Any unreasonable
                    delay in notifying BITTOKEN may not only affect the security
                    of the account, but may also cause the user to be liable for
                    any losses. If the user suspects that other people have
                    accessed the user account, the user should also contact the
                    appropriate government agency and report the incident.
                  </span>
                </p>
                <p>
                  <span>
                    16.5. The user must be careful to ensure the security of the
                    user"s email account and mobile phone, and can only be
                    accessed by the user, because the user"s email address and
                    mobile phone may be used to reset the password or
                    communicate with the user about account security.
                  </span>
                </p>
                <p>
                  <span>
                    16.6. Any withdrawal transaction can only be carried out
                    after two-factor authentication.
                  </span>
                </p>
                <p>
                  <span>17. Termination of Terms of Use</span>
                </p>
                <p>
                  <span>
                    17.1. The user can terminate the terms of use and close his
                    account at any time after completing any processing
                    transactions and paying any other payments due to BITTOKEN
                    or resolving disputes with BITTOKEN.
                  </span>
                </p>
                <p>
                  <span>
                    17.2. BITTOKEN can terminate user access to the platform and
                    user account at its sole discretion by issuing a notice,
                    including but not limited to, BITTOKEN has the right to:
                    restrict, suspend or terminate the service and user account,
                    prohibit access to the service, delay or delete the hosted
                    content, and adopt technology and Legal measures prevent
                    users from accessing the platform (whether or not a reason
                    is given). In particular, BITTOKEN may decide to suspend or
                    terminate user accounts for any reason under appropriate
                    circumstances, including but not limited to: (1) Attempts to
                    access the website or other user’s accounts without
                    authorization or to provide assistance to others in this
                    regard, (2) Break through the software restriction to use
                    and protect the security function of any content, (3) use
                    the service to carry out illegal activities, such as money
                    laundering, illegal gambling activities, financing of
                    terrorism or other criminal activities, (4) violate these
                    terms of use, (5) not Payment of transaction payments or
                    fraudulent payments, (6) Unforeseen operational
                    difficulties, (7) At the request of law enforcement agencies
                    or other government agencies, or (8) Accepting any third
                    party’s entrustment or acting for or on behalf of any third
                    party , Accept funds or commissions provided by third-party
                    offline channels or other channels, and use the user"s own
                    account to conduct transactions for them. If BITTOKEN
                    considers this to be legal and necessary, it may act at its
                    sole discretion.
                  </span>
                </p>
                <p>
                  <span>
                    17.3. BITTOKEN also reserves the right to cancel unproven
                    accounts and accounts that have been inactive for 6 months
                    or more and/or modify or terminate the platform or service.
                    The user agrees that BITTOKEN will not be liable for such
                    users or any third parties for such termination of accounts
                    or termination of access to the platform.
                  </span>
                </p>
                <p>
                  <span>
                    17.4. Suspension or termination of an account will not have
                    an impact on the payment obligations of commissions payable
                    in the past transactions. After termination, the customer
                    should provide a cryptocurrency address to transfer funds in
                    the account. BITTOKEN shall transfer funds to the address
                    within the time specified by BITTOKEN after providing the
                    cryptocurrency address that accepts the funds transfer
                    according to the user"s requirements.
                  </span>
                </p>
                <p>
                  <span>18. Service availability</span>
                </p>
                <p>
                  <span>
                    18.1. Except as clearly stated in the terms of use, BITTOKEN
                    and its suppliers, service providers, contractors or
                    distributors have not made any specific commitments to the
                    services, including but not limited to the content of the
                    service, the specific functions of the service, and the
                    service Reliability, availability of services or ability to
                    meet your specific requirements. BITTOKEN provides services
                    "as is". To the extent permitted by law, BITTOKEN excludes
                    all implied warranties (marketability, applicability for a
                    specific purpose, non-infringement, etc.) related to the
                    platform or service.
                  </span>
                </p>
                <p>
                  <span>
                    18.2. BITTOKEN will use commercially reasonable efforts to
                    maintain the normal operation of the platform; however, all
                    network services will be affected by accidental damage and
                    interruption, and BITTOKEN will not be liable for any
                    interruption or loss suffered by users due to damage or
                    interruption. Therefore, BITTOKEN cannot provide any
                    guarantee that platform access will not be interrupted, or
                    that there will be no delays, failures, errors, omissions or
                    loss of transmission information.
                  </span>
                </p>
                <p>
                  <span>
                    18.3. BITTOKEN will use commercially reasonable efforts to
                    ensure that users can access the platform normally in
                    accordance with these terms of use. BITTOKEN may suspend any
                    part of the platform for maintenance and will make
                    commercially reasonable efforts to notify users. The user
                    acknowledges that it may not be practicable to issue notices
                    in certain emergency situations.
                  </span>
                </p>
                <p>
                  <span>19. Mobile application</span>
                </p>
                <p>
                  <span>
                    19.1. BITTOKEN shall provide an application that can be used
                    to access the account. The use of the application is also
                    subject to these terms of use.
                  </span>
                </p>
                <p>
                  <span>20. Financial or legal advice</span>
                </p>
                <p>
                  <span>
                    20.1. BITTOKEN does not provide any financial, investment or
                    legal advice on the services provided by BITTOKEN. BITTOKEN
                    may provide information related to the price, scope,
                    volatility of cryptocurrency and events that have affected
                    the price of cryptocurrency, but this should not be regarded
                    as investment or financial advice, and should not be
                    interpreted accordingly. Any decision to buy or sell a
                    cryptocurrency contract is the user"s own decision, and
                    BITTOKEN will not be liable for any losses suffered by the
                    user. Users can seek financial, investment or legal advice
                    from user advisors, or respond to information or data
                    provided by others to participate in and/or complete
                    transactions.
                  </span>
                </p>
                <p>
                  <span>21. Tax</span>
                </p>
                <p>
                  <span>
                    21.1. The user undertakes to pay all taxes and duties that
                    may arise from using the service, participating in, and/or
                    completing transactions, and shall pay such taxes and duties
                    in accordance with applicable laws.
                  </span>
                </p>
                <p>
                  <span>
                    21.2. BITTOKEN is not responsible for any violations caused
                    by the user"s obligation to calculate and pay taxes and
                    duties.
                  </span>
                </p>
                <p>
                  <span>22. Notification and communication</span>
                </p>
                <p>
                  <span>
                    22.1. BITTOKEN reserves the right to send notifications and
                    communications to users through any communication methods
                    available to BITTOKEN (including but not limited to the
                    contact information provided by users).
                  </span>
                </p>
                <p>
                  <span>
                    22.2. The user expressly agrees to receive and be bound by
                    any electronic notification (if required by the terms of
                    use).
                  </span>
                </p>
                <p>
                  <span>23. Applicable laws and dispute resolution</span>
                </p>
                <p>
                  <span>
                    23.1. Unless expressly stipulated otherwise, the terms of
                    use shall be governed by and interpreted in accordance with
                    the law.
                  </span>
                </p>
                <p>
                  <span>
                    23.2. All disputes, disputes and claims arising from or
                    related to these terms of use, including the validity,
                    invalidity, violation and termination of the contract, tort
                    or any other matter based on the principles of law and
                    equity, shall be subject to arbitration The international
                    arbitration rules ("Arbitration Rules") valid at the time of
                    submission of the Swiss Chamber of Commerce Arbitration
                    Court shall be resolved through arbitration.
                  </span>
                </p>
                <p>
                  <span>
                    23.3. If any part of the terms of use is deemed invalid,
                    invalid or unenforceable for any reason, that part shall be
                    deemed severable and shall not affect the validity and
                    enforceability of any remaining parts.
                  </span>
                </p>
                <p>
                  <span>24. Limited Liability</span>
                </p>
                <p>
                  <span>
                    In any case, BITTOKEN and its executives, directors,
                    employees and third-party service providers shall not be
                    liable for any indirect, incidental, special, punitive or
                    incidental damages to users or any other individuals or
                    entities, including the following Any damage caused by the
                    cause:
                  </span>
                </p>
                <p>
                  <span>
                    (i) Accuracy, completeness and content of the platform or
                    service
                  </span>
                </p>
                <p>
                  <span>
                    (ii) The accuracy, completeness and content of any website
                    linked to the platform (through hyperlinks, banner
                    advertisements or other means)
                  </span>
                </p>
                <p>
                  <span>
                    (iii) Services found on the platform or any website linked
                    to this website (through hyperlinks, banner advertisements
                    or other means)
                  </span>
                </p>
                <p>
                  <span>(iv) Property damage of any nature</span>
                </p>
                <p>
                  <span>(v) Acts of third parties of any nature</span>
                </p>
                <p>
                  <span>
                    (vi) Any unauthorized access or use of the server and/or any
                    and all content, personal information, financial information
                    or other information and data stored here
                  </span>
                </p>
                <p>
                  <span>
                    (vii) Any link (through hyperlink, banner advertisement or
                    other means) to the website of this website that causes any
                    interruption or suspension of service and access to the
                    platform
                  </span>
                </p>
                <p>
                  <span>
                    (viii) Any website viruses, worms, vulnerabilities, Trojan
                    horses, etc. that can enter and exit the platform or any
                    link (through hyperlinks, banner advertisements or other
                    means) to this website
                  </span>
                </p>
                <p>
                  <span>
                    (ix) Any user content or other content that is defamatory,
                    harassing, abusive, harmful to minors or any protected
                    group, pornographic, "X-rated", obscene or other offensive
                    user content and/or
                  </span>
                </p>
                <p>
                  <span>
                    (x) Any loss or damage found on the platform caused by the
                    user"s use of the platform or service, regardless of whether
                    it is based on guarantee, contract, tort or any other legal
                    or fair theory, and BITTOKEN has been notified that such
                    damage may occur. In addition, the user expressly
                    acknowledges and agrees that any cause of litigation found
                    on the website caused by or related to the platform or
                    service must be brought within one year after the cause of
                    litigation arises, otherwise the claims for the cause of
                    litigation will be permanently prohibited. In addition, the
                    user expressly acknowledges and agrees that under any
                    circumstances, BITTOKEN"s total liability limit shall not
                    exceed the total amount paid by the user for the service as
                    the main cause of action. The above limitation of liability
                    shall be applied to the maximum extent permitted by law, and
                    shall continue to be effective after the termination or
                    expiration of these terms of use or after the user uses this
                    website or uses the services found on this website.
                  </span>
                </p>
                <p>
                  <span>25. Compensation</span>
                </p>
                <p>
                  <span>
                    25.1. The user agrees to protect, defend, compensate and
                    protect BITTOKEN and its executives, directors, employees
                    and third-party service providers from any and all types and
                    nature of claims directly or indirectly suffered or assumed
                    by BITTOKEN due to the following circumstances,
                    Requirements, costs, expenses, losses, liabilities and
                    damages (including but not limited to reasonable attorney
                    fees):
                  </span>
                </p>
                <p>
                  <span>(i) Users access and use platforms or services;</span>
                </p>
                <p>
                  <span>
                    (ii) The user violates any provision in the terms of use or
                    policy or agreement; and/or
                  </span>
                </p>
                <p>
                  <span>
                    (iii) The user violates the rights of any third party,
                    including but not limited to any intellectual property
                    rights or other proprietary rights. The compensation
                    obligation under this clause shall continue to be effective
                    after the termination or expiration of these terms of use or
                    after the user uses the platform and services.
                  </span>
                </p>
                <p>
                  <span>26. Modification and revision</span>
                </p>
                <p>
                  <span>
                    BITTOKEN reserves the right to revise or modify any part of
                    the terms of use by publishing a revised version of the
                    terms of use on the platform at any time. This change will
                    take effect, and will be deemed accepted by the user when
                    the user first visits the platform or uses the service after
                    the revised terms of use is released, and shall continue to
                    be applied in any activities initiated after its release. If
                    the user does not agree to these modifications, the user"s
                    only and exclusive remedy is to terminate the use of the
                    service and close the account. The user agrees that BITTOKEN
                    will not be liable to the user and any third party for any
                    loss caused by the modification or revision of the terms of
                    use.
                  </span>
                </p>
                <p>
                  <span>27. Force Majeure</span>
                </p>
                <p>
                  <span>
                    27.1. If BITTOKEN is unable to perform the services outlined
                    in the terms of use due to factors beyond its control
                    (including but not limited to force majeure, legal changes
                    or sanctions policy changes), BITTOKEN will not be liable to
                    the user for the inconsistency between the services provided
                    under the agreement and the incident Any responsibility.
                  </span>
                </p>
                <p>
                  <span>28. Link to third-party websites</span>
                </p>
                <p>
                  <span>
                    28.1. The platform may contain links to third-party websites
                    that are not owned or controlled by BITTOKEN. BITTOKEN is
                    not responsible for the content, terms and conditions,
                    privacy policy or behavior of any third-party website. In
                    addition, BITTOKEN will not review or edit the content of
                    any third-party websites. By using the platform or service,
                    you expressly release BITTOKEN from all liabilities arising
                    from the user"s use of any third-party website. Therefore,
                    BITTOKEN encourages users to pay attention to and review the
                    terms and conditions, privacy policies and other management
                    documents of any other websites that users may visit.
                  </span>
                </p>
                <p>
                  <span>29. No third party rights</span>
                </p>
                <p>
                  <span>
                    29.1. The terms of use only apply to the relationship
                    between the user and BITTOKEN, and do not create any
                    third-party rights of any nature.
                  </span>
                </p>
                <p>
                  <span>30. Partial validity</span>
                </p>
                <p>
                  <span>
                    30.1. If any clause or part of the clause in the terms of
                    use is deemed invalid, illegal, invalid or unenforceable
                    under an applicable law, it shall be deemed as a minimum
                    necessary modification in accordance with the applicable
                    law. In order to make it effective, legal and enforceable
                    under the applicable law. If the above-mentioned
                    modification is not possible, the clauses or part of the
                    clauses involved shall be deemed to have been deleted under
                    the applicable law. Any modification or deletion of the
                    terms or parts of the terms in accordance with these terms
                    shall not affect the validity and enforceability of the
                    remaining parts of the terms of use under the applicable
                    law, and the validity of the terms or parts of the terms
                    involved under other applicable laws And enforceability.
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <br />
                </p>
                <div className="talr">
                  <b>Localbitcoins</b>
                  <br />
                  2021-10-17
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalTerm;
