import React, { useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faChevronRight,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { useNotify } from "_common/component/NotifyProvider";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import classNames from "classnames";

const schema = yup
  .object({
    address: yup.string(),
    amount: yup.number().required(i18next.t("verify_require")),
    wallet_password: yup.string().required(i18next.t("verify_require")),
  })
  .required();

export default function WithdrawSubmit() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { pushNotify } = useNotify();

  const symbol = params.get("symbol");
  const network = params.get("network");

  const {
    authStore: { user, general, coins, bank_default },
  } = useStores();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const amount = watch("amount");

  const mainCoin = useMemo(
    () => coins?.find((obj) => obj?.symbol === symbol),
    [coins, symbol]
  );

  const mainBalance = useMemo(
    () => user?.tokens?.find((obj) => obj?.symbol === symbol),
    [user, symbol]
  );

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.withdraw({
        params: {
          ...variables,
          symbol,
          bank_id: bank_default?.id,
          type: "withdraw",
        },
      }),
    {
      onSuccess: (res) => {
        pushNotify({
          type: "withdraw",
        });
        reset();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    // if (!bank_default?.id)
    //   return NotificationManager.error("Vui lòng liên kết tài khoản ngân hàng");

    if (amount < Number(general?.min_withdraw || 0))
      return NotificationManager.error(
        `${i18next.t("enter_large_amount_msg")} ${formatNumber(
          general?.min_withdraw
        )}`
      );
    if (amount > Number(general?.max_withdraw || 0))
      return NotificationManager.error(
        `${i18next.t("enter_smaller_amount_msg")} ${formatNumber(
          general?.max_withdraw
        )}`
      );
    onSubmit({ ...values });
    return null;
  };

  return (
    <div className="container">
      <HeaderTop title={i18next.t("withdraw")} />

      <div id="withdraw" className="pt-[50px] px-[15px]">
        <div className="contentbox_line">
          <div className="line_1">
            <span className="fzmm fe6im">USD/{mainCoin?.name}</span>
          </div>
          <div className="line_2" onClick={() => navigate(-1)}>
            <span className="fzmm text-[#b6b6b6] font-[12px] mr-[5px]">
              {i18next.t("select_currency")}
            </span>
            <FontAwesomeIcon icon={faChevronRight} color="#707A8A" />
          </div>
        </div>
        {/* {!bank_default?.id && (
          <div
            className="contentbox_line"
            onClick={() => navigate("/member/bank/submit?bank_type=local")}
          >
            <div className="line_1">
              <span className="fzmm fe6im">Liên kết tài khoản ngân hàng</span>
            </div>
            <div className="line_2">
              <FontAwesomeIcon icon={faChevronRight} color="#707A8A" />
            </div>
          </div>
        )} */}
        <div className="imgbox">
          {symbol === "usdt" && (
            <div className="flex justify-center gap-2">
              <div
                className={classNames("imgbox_3 fl czline", {
                  showQr: network === "erc20",
                })}
                onClick={() =>
                  navigate(
                    "/member/withdraw/submit?symbol=usdt&network=erc20",
                    {
                      replace: true,
                    }
                  )
                }
              >
                <span className="fzmm">ERC20</span>
              </div>
              <div
                className={classNames("imgbox_3 fl czline", {
                  showQr: network === "trc20",
                })}
                onClick={() =>
                  navigate(
                    "/member/withdraw/submit?symbol=usdt&network=trc20",
                    {
                      replace: true,
                    }
                  )
                }
              >
                <span className="fzmm">TRC20</span>
              </div>
              <div
                className={classNames("imgbox_3 fl czline", {
                  showQr: network === "omni",
                })}
                onClick={() =>
                  navigate("/member/withdraw/submit?symbol=usdt&network=omni", {
                    replace: true,
                  })
                }
              >
                <span className="fzmm">OMNI</span>
              </div>
              <div
                className={classNames("imgbox_3 fl czline", {
                  showQr: network === "bank",
                })}
                onClick={() =>
                  navigate("/member/withdraw/submit?symbol=usdt&network=bank", {
                    replace: true,
                  })
                }
              >
                <span className="fzmm uppercase">{i18next.t("bank")}</span>
              </div>
            </div>
          )}
        </div>
        <form className="imgbox !mt-0" onSubmit={handleSubmit(onSave)}>
          <div className="imgbox_4 h-[370px] !border-[0] !mt-[0]">
            <div className="imgbox_1 flex justify-between">
              <div className="h-[40px] leading-[40px]">
                <span className="fzmm fe6im">
                  {i18next.t("extract_quantity")}
                </span>
              </div>
            </div>
            <div className="tx_inputbox">
              <div className="tx_inputbox_l !w-[65%]">
                <input
                  readOnly
                  className="w-full h-[30px]"
                  value={formatNumber(mainBalance?.amount, "0,0.0000")}
                />
              </div>
              <div className="tx_inputbox_r !w-[35%]">
                <div className="inputbox_r_r">
                  <span className="fzmm fcc !text-[14px]">
                    {mainCoin?.name}
                  </span>
                </div>
              </div>
            </div>
            <div className="imgbox_1  mt-[10px] flex justify-between">
              <div className="h-[40px] leading-[40px]">
                <span className="fzmm fe6im">
                  {i18next.t("enter_withdraw_amount")}
                </span>
              </div>
            </div>
            <div className="tx_inputbox">
              <div className="tx_inputbox_l !w-[100%]">
                <input
                  type="text"
                  autoComplete="off"
                  className="w-full h-[30px]"
                  {...register("address")}
                />
              </div>
            </div>
            <p className="text-[#b9b9b9] mt-[4px] text-sm">
              {i18next.t("blank_amount")}
            </p>
            <div className="imgbox_1  mt-[10px] flex justify-between">
              <div className="h-[40px] leading-[40px]">
                <span className="fzmm fe6im">
                  {i18next.t("withdraw_amount")}
                </span>
              </div>
            </div>
            <div className="tx_inputbox">
              <div className="tx_inputbox_l !w-[60%]">
                <input
                  type="number"
                  inputMode="decimal"
                  min={0}
                  max={1000000000000}
                  autoComplete="off"
                  className="w-full h-[30px]"
                  {...register("amount")}
                />
              </div>
              <div className="tx_inputbox_r !w-[40%]">
                <div className="inputbox_r_r !w-full !text-right">
                  <span className="fzmm fcc !text-[14px]">
                    USD/{mainCoin?.name} | {i18next.t("all")}
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="imgbox_1 mt-[10px]">
              <span className="fzmm fe6im">Mật khẩu rút tiền</span>
            </div>
            <div className="tx_inputbox">
              <div className="tx_inputbox_l">
                <input
                  type="password"
                  placeholder="Mật khẩu rút tiền"
                  className="w-full h-[30px] text-[#707a8a]"
                  {...register("wallet_password")}
                />
              </div>
            </div> */}
            <div className="imgbox_8 !h-[unset] mt-[20px]">
              <div className="imgbox_10 !bg-[#78C9EB]" id="sumbtn">
                <button
                  type="submit"
                  className="fzmm uppercase"
                  disabled={!isValid}
                >
                  {i18next.t("withdraw")}
                </button>
              </div>
            </div>
            <div className="flex items-center mt-[10px]">
              <span className="fzm fcc">{i18next.t("fee")}:&nbsp;&nbsp;</span>
              <span className="fzm fcc">
                {formatNumber(amount * 0.03, "0,0.0000")}
              </span>
              <span className="fzm fcc uppercase">
                &nbsp;&nbsp;USD/{mainCoin?.symbol}
              </span>
            </div>
            <div className="flex items-center">
              <span className="fzm fcc">
                {i18next.t("arrived_amount")}:&nbsp;&nbsp;
              </span>
              <span className="fzm fcc">
                {formatNumber(amount * 0.97, "0,0.0000[000]")}
              </span>
              <span className="fzm fcc uppercase">
                &nbsp;&nbsp;USD/{mainCoin?.symbol}
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
