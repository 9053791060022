import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import Banner from "./Banner";
import Notice from "./Notice";
import Why from "./Why";
import MarketPrice from "./MarketPrice";
import News from "./News";
import Trade from "./Trade";

const DesktopHome = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  return (
    <main className="css-1wr4jig">
      <Banner />
      <div className="index-images">
        {banners.map((item, index) => (
          <div key={`id-${index?.toString()}`}>
            <img alt="" src={item?.image_url} height="148" />
          </div>
        ))}
      </div>
      <div>
        <div
          className="css-1t9l9dt"
          style={{ width: "100%", padding: "0 24px" }}
        >
          <div className="box box_top">
            <Notice />
          </div>
        </div>
      </div>
      <div className="css-194m5n4" style={{ paddingTop: 30 }}>
        <MarketPrice />
      </div>
      <Why />
      <News />
      <Trade />
    </main>
  );
});

export default DesktopHome;
