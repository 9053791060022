import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faLongArrowAltLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useNotify } from "_common/component/NotifyProvider";
import NoData from "_common/component/NoData";
import classNames from "classnames";

export default function HistoryTrade({ status }) {
  const navigate = useNavigate();
  const [item, setItem] = useState();
  const { dataGame } = useNotify();

  const { data, refetch } = useQuery(
    ["game-player", "list", status],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 50,
          status,
          type: "spot",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  if (!data?.data?.length) return <NoData />;

  return (
    <div className="mx-auto w-full">
      <div className="list-history-data ">
        {get(data, "data", [])?.map((item) => {
          const time = Number(get(dataGame?.[item?.id], "time", 0));
          const type = Number(get(item, "type", 360));
          const process = (time / type) * 100;
          return (
            <div key={item.id} className="listbox">
              <div className="listbox_title">
                <div className="w-full">
                  <span
                    className={`f14 text-[16px] text-[500] text-[#0ecb81] ${
                      item?.bet_game === "sell" && "text-[#f5465c]"
                    }`}
                  >
                    {i18next.t(item?.bet_game)}
                  </span>
                  <span className="ml-[5px] text-[13px] text-[#888999]">
                    {moment(item?.created_at).format("MM-DD HH:mm:ss")}
                  </span>
                  <span className="ml-[5px] text-[13px] text-[#888999]">
                    {i18next.t("close_time")}
                  </span>
                  <span className="ml-[5px] text-[13px] text-[#888999]">
                    {moment(item?.created_at).format("MM-DD HH:mm:ss")}
                  </span>
                </div>
              </div>
              <div className="w-full h-[60px]">
                <div className="w-[33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px]">
                    <span className="f12 text-[#888999]">
                      {item?.game?.name}
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[30px]">
                    <span className="f12 text-[#000]">
                      {formatNumber(item?.open, "0,0.0000")}
                    </span>
                  </div>
                </div>
                <div className="w-[33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px] text-center">
                    <span className="f12 text-[#888999]">
                      {i18next.t("amount")}
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    <span className="f12 text-[#000]">
                      {formatNumber(item?.amount)}
                    </span>
                  </div>
                </div>
                <div className="w-[33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px] text-right">
                    <span className="f12 text-[#888999]">
                      {i18next.t("close_price")}
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[30px] text-right">
                    <span className="f12 text-[#78C9EB]">
                      {item?.status === "bet"
                        ? "Đang tiến hành"
                        : formatNumber(item?.close, "0,0.0000")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full h-[60px]">
                <div className="w-[33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px]">
                    <span className="f12 text-[#888999]">
                      {i18next.t("time")}
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[30px]">
                    <span className="f12 text-[#000]">{item?.type} Sec</span>
                  </div>
                </div>
                <div className="w-[33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px] text-center">
                    <span className="f12 text-[#888999]">
                      {i18next.t("profit_usdt")}
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    <span
                      className={classNames("f12 ", {
                        "text-[#000]": item?.status === "bet",
                        "text-[#78C9EB]": item?.status === "win",
                        "text-[#ff0000]": item?.status === "lose",
                      })}
                    >
                      {item?.status === "bet" && "-"}
                      {item?.status === "win" &&
                        `+${formatNumber(item?.amount_payback)}`}
                      {item?.status === "lose" &&
                        `-${formatNumber(item?.amount_lose)}`}
                    </span>
                  </div>
                </div>
                <div className="w-[33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px] text-right">
                    <span className="f12 text-[#888999]">
                      {i18next.t("status")}
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[30px] text-right">
                    <span className="f12 text-[#000]">
                      {item?.status === "bet" ? "Processing" : "Closed"}
                    </span>
                  </div>
                </div>
              </div>
              {item?.status === "bet" && (
                <div className="progress h-[10px] bg-[#1b1d29]">
                  <div
                    className="progress-bar"
                    style={{ width: `${process}%` }}
                  >
                    <span className="time">
                      {dataGame?.[item?.id]?.time} Sec
                    </span>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
