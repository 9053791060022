import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatNumber } from "_common/utils/formatValue";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import classNames from "classnames";

const Commodity = observer(({ onClose }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games },
  } = useStores();

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const coinListPrice = useMemo(
    () =>
      games
        ?.filter((obj) => obj?.type === "commodity")
        ?.map((item) => {
          const price = data?.find(
            (obj) => obj?.symbol?.toLowerCase() === item?.code
          );

          return { ...item, price };
        }),
    [games, data]
  );

  return (
    <div className="coin-list">
      <div className="list">
        <div className="h-[100vh] overflow-y-scroll">
          {coinListPrice?.map((item) => (
            <div
              onClick={() => {
                navigate(`/board/spot?symbol=${item?.code}`);
                onClose();
              }}
              className="item !bg-[transparent]"
              key={item?.id}
            >
              <div className="col1">
                <div className="flex items-center gap-2">
                  <img
                    className="w-[20px] h-[20px] mr-[10px]"
                    src={item?.image_url}
                    alt=""
                  />
                  <p className="name">{item?.name?.split("/")?.[0]}</p>
                </div>
              </div>
              <div className="col2">
                <span
                  className={classNames({
                    "text-green": item?.price?.priceChangePercent > 0,
                    "text-red": item?.price?.priceChangePercent <= 0,
                  })}
                >
                  {formatNumber(item?.price?.priceChangePercent, "0,0.[00]")}%
                </span>
              </div>
              <div className="col3 !justify-center">
                <span>
                  {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Commodity;
