import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { FundApi } from "states/api/fund";
import logo from "assets/img/logo.png";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate } from "react-router-dom";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import FundItem from "./FundItem";

export default function ListData({ data = [] }) {
  const navigate = useNavigate();

  return (
    <div className="sbui-loading">
      <HeaderTop
        title={i18next.t("staking")}
        right={
          <span
            className="text-[18px] text-[#78C9EB]"
            onClick={() => navigate("/board/fund/history")}
          >
            <FontAwesomeIcon icon={faFileAlt} />
          </span>
        }
      />
      <div className="list-history-data">
        {data?.map((item) => (
          <FundItem key={item?.id} item={item} />
        ))}
      </div>
    </div>
  );
}
