import React from "react";
import { Portal } from "react-portal";
import Usdt from "./Usdt";

export default function ListCoin({ open, onClose }) {
  return (
    <Portal>
      <div className="left-draw" style={{ display: open ? "block" : "none" }}>
        <div className="mask" onClick={onClose} />
        <div className="content">
          <Usdt onClose={onClose} />
        </div>
      </div>
    </Portal>
  );
}
