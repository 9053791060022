import i18next from "i18next";
import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useStores } from "_common/hooks";

export default function DesktopFooter() {
  const location = useLocation();
  const pathName = location?.pathname;
  const {
    authStore: { logo },
  } = useStores();

  return (
    <footer
      data-locale="zh-cn"
      data-hostnmae="BITTOKEN.vip"
      className="fedui-root fedui-footer"
    >
      <div className="fedui-footer-inner">
        <div className="fedui-footer-left">
          <div className="fedui-footer-logo">
            <img width="80" height="auto" src={logo} alt="" />
          </div>
        </div>
        <div className="fedui-footer-right">
          <dl>
            <dt className="ft16">About</dt>
            <dd>
              <a href="/#">About BITTOKEN</a>
            </dd>
          </dl>
          <dl>
            <dt>Terms</dt>
            <dd>
              <a href="/#">Safety</a>
              <a href="/#">Terms agreement</a>
              <a href="/#">Disclaimer</a>
              <a href="/#">Privacy Policy</a>
            </dd>
          </dl>
          <dl>
            <dt>Support</dt>
            <dd>
              <a href="/#">info@tcehk.com</a>
            </dd>
          </dl>
        </div>
      </div>
      <div
        className=" h-[60px]"
        style={{
          textAlign: "center",
          borderTop: "1px solid rgb(48, 48, 53)",
          marginTop: 20,
        }}
      >
        <div className="fColor2 ft12" style={{ padding: "20px 0" }}>
          ©Copyright 2019-2023 BITTOKEN. All rights reserved.{" "}
        </div>
      </div>
    </footer>
  );
}
