import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

const Notice = observer(() => {
  const navigate = useNavigate();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  return (
    <div className="top">
      <div className="item-top d-inline-flex align-items-start">
        <a
          href="le-ho-leveraged-token"
          className="d-inline-block name mr-2 text-left"
        >
          Beware of criminals impersonating Bittoken for fraud
        </a>
      </div>
      <div className="item-top d-inline-flex align-items-start">
        <a
          href="le-ho-leveraged-token"
          className="d-inline-block name mr-2 text-left"
        >
          Welcome Bittoken
        </a>
      </div>
      <div className="item-top d-inline-flex align-items-start">
        <a
          href="le-ho-leveraged-token"
          className="d-inline-block name mr-2 text-left"
        >
          Beware of criminals impersonating Bittoken for fraud
        </a>
      </div>
      <div className="item-top d-inline-flex align-items-start">
        <a
          href="le-ho-leveraged-token"
          className="d-inline-block name mr-2 text-left"
        >
          Welcome Bittoken
        </a>
      </div>
      <div className="item-top d-inline-flex align-items-start">
        <a
          href="le-ho-leveraged-token"
          className="d-inline-block name mr-2 text-left"
        >
          Beware of criminals impersonating Bittoken for fraud
        </a>
      </div>
      <div className="item-top d-inline-flex align-items-start">
        <a
          href="le-ho-leveraged-token"
          className="d-inline-block name mr-2 text-left"
        >
          Welcome Bittoken
        </a>
      </div>
    </div>
  );
});

export default Notice;
