import React from "react";
import { useStores } from "_common/hooks";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { handleCopyLink } from "_common/utils/helper";
import QRCode from "react-qr-code";

import imgBgRef from "assets/img/bg-ref.jpg";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ShareLink() {
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="container !w-[100%]">
      <div
        className="fixed top-[20px] right-[20px] w-[24px] h-[24px] z-20"
        onClick={() => navigate("/member")}
      >
        <FontAwesomeIcon className="text-white text-[24px]" icon={faTimes} />
      </div>
      <div className=" mu-card-media !w-full">
        <img src={imgBgRef} alt="" />
        <div className="mu-card-media-title text-center">
          <div className="mu-card-title">SGR invite</div>
          <div className="mu-card-sub-title">Earn high returns together</div>
        </div>
      </div>
      <div className="no_content w-[90%] mx-auto">
        <div className="no_inbox">
          <div className="logobox_right !w-[100%]">
            <div className="w-full h-[20px] leading-[20px] text-center">
              <span className="fe6im text-[14px]">My invitation code</span>
            </div>
            <div className="w-full h-[20px] leading-[20px] mt-[10px] text-center">
              <span className="text-[26px] font-bold text-[#0a8415]">
                {user.ref_no}
              </span>
            </div>
          </div>
          <div className="logobox !h-[230px] mt-[10px] flex justify-center">
            <div className="w-[200px] h-[200px] mt-[20px]">
              {user.link_ref && (
                <QRCode
                  size={256}
                  viewBox="0 0 256 256"
                  className="w-[200px] h-[200px]"
                  value={user.link_ref}
                />
              )}
            </div>
          </div>
          <div>
            <h4 className="text-center py-[10px] text-[16px]">
              My sharing link
            </h4>
            <input
              className="w-[100%] h-[44px] py-[5px] px-[12px] rounded-[10px] bg-[#eeeeee] text-black"
              readOnly
              value={user.link_ref}
              onClick={() => handleCopyLink(user.link_ref)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
