import React, { useState } from "react";
import bgMember from "assets/img/bg-member.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faBell,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ModalInfo from "./ModalInfo";
import ModalPrivacyPolicy from "./ModalPrivacyPolicy";
import ModalDisclaimer from "./ModalDisclaimer";
import ModalTerm from "./ModalTerm";
import ModalSafety from "./ModalSafety";

export default function About() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);
  return (
    <>
      <div className="container">
        <div
          className="fixed top-[20px] right-[20px] w-[24px] h-[24px] z-20"
          onClick={() => navigate("/member")}
        >
          <FontAwesomeIcon className="text-white text-[24px]" icon={faTimes} />
        </div>
        <div className="mu-card">
          <div className=" mu-card-media !w-full">
            <img src={bgMember} alt="" />
            <div className="mu-card-media-title">
              <div className="mu-card-title">Help</div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="mu-list mu-list-two-line">
            <li>
              <div
                className="mu-item-wrapper"
                onClick={() => setOpenModal(true)}
              >
                <div className="mu-item  has-avatar  ">
                  <div className="mu-ripple-wrapper" />
                  <div className="mu-item-action  ">
                    <div className="mu-avatar mu-primary-color mu-inverse w-[40px] h-[40px] text-[20px]">
                      <div className="mu-avatar-inner">
                        <FontAwesomeIcon icon={faBell} />
                      </div>
                    </div>
                  </div>
                  <div className="mu-item-content px-[10px] py-0">
                    <div className="mu-item-title">About BITTOKEN</div>
                    <div className="mu-item-sub-title">2021-10-17</div>
                  </div>
                  <div className="mu-item-action  ">
                    <button type="button" className="mu-button mu-icon-button ">
                      <div className="mu-button-wrapper">
                        <div className="mu-ripple-wrapper" />
                        <FontAwesomeIcon icon={faAngleRight} />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div
                className="mu-item-wrapper"
                onClick={() => setOpenModal1(true)}
              >
                <div className="mu-item  has-avatar  ">
                  <div className="mu-ripple-wrapper" />
                  <div className="mu-item-action  ">
                    <div className="mu-avatar mu-warning-color mu-inverse w-[40px] h-[40px] text-[20px]">
                      <div className="mu-avatar-inner">
                        <FontAwesomeIcon icon={faBell} />
                      </div>
                    </div>
                  </div>
                  <div className="mu-item-content px-[10px] py-0">
                    <div className="mu-item-title">Privacy Policy</div>
                    <div className="mu-item-sub-title">2021-10-17</div>
                  </div>
                  <div className="mu-item-action  ">
                    <button type="button" className="mu-button mu-icon-button ">
                      <div className="mu-button-wrapper">
                        <div className="mu-ripple-wrapper" />
                        <FontAwesomeIcon icon={faAngleRight} />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div
                className="mu-item-wrapper"
                onClick={() => setOpenModal2(true)}
              >
                <div className="mu-item  has-avatar  ">
                  <div className="mu-ripple-wrapper" />
                  <div className="mu-item-action  ">
                    <div className="mu-avatar mu-primary-color mu-inverse w-[40px] h-[40px] text-[20px]">
                      <div className="mu-avatar-inner">
                        <FontAwesomeIcon icon={faBell} />
                      </div>
                    </div>
                  </div>
                  <div className="mu-item-content px-[10px] py-0">
                    <div className="mu-item-title">Disclaimer</div>
                    <div className="mu-item-sub-title">2021-10-17</div>
                  </div>
                  <div className="mu-item-action  ">
                    <button type="button" className="mu-button mu-icon-button ">
                      <div className="mu-button-wrapper">
                        <div className="mu-ripple-wrapper" />
                        <FontAwesomeIcon icon={faAngleRight} />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div
                className="mu-item-wrapper"
                onClick={() => setOpenModal3(true)}
              >
                <div className="mu-item  has-avatar  ">
                  <div className="mu-ripple-wrapper" />
                  <div className="mu-item-action  ">
                    <div className="mu-avatar mu-warning-color mu-inverse w-[40px] h-[40px] text-[20px]">
                      <div className="mu-avatar-inner">
                        <FontAwesomeIcon icon={faBell} />
                      </div>
                    </div>
                  </div>
                  <div className="mu-item-content px-[10px] py-0">
                    <div className="mu-item-title">Terms agreement</div>
                    <div className="mu-item-sub-title">2021-10-17</div>
                  </div>
                  <div className="mu-item-action  ">
                    <button type="button" className="mu-button mu-icon-button ">
                      <div className="mu-button-wrapper">
                        <div className="mu-ripple-wrapper" />
                        <FontAwesomeIcon icon={faAngleRight} />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div
                className="mu-item-wrapper"
                onClick={() => setOpenModal4(true)}
              >
                <div className="mu-item  has-avatar  ">
                  <div className="mu-ripple-wrapper" />
                  <div className="mu-item-action  ">
                    <div className="mu-avatar mu-primary-color mu-inverse w-[40px] h-[40px] text-[20px]">
                      <div className="mu-avatar-inner">
                        <FontAwesomeIcon icon={faBell} />
                      </div>
                    </div>
                  </div>
                  <div className="mu-item-content px-[10px] py-0">
                    <div className="mu-item-title">Safety</div>
                    <div className="mu-item-sub-title">2021-10-17</div>
                  </div>
                  <div className="mu-item-action  ">
                    <button type="button" className="mu-button mu-icon-button ">
                      <div className="mu-button-wrapper">
                        <div className="mu-ripple-wrapper" />
                        <FontAwesomeIcon icon={faAngleRight} />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </div>
        </div>
      </div>
      {openModal && (
        <ModalInfo open={openModal} onClose={() => setOpenModal(false)} />
      )}
      {openModal1 && (
        <ModalPrivacyPolicy
          open={openModal1}
          onClose={() => setOpenModal1(false)}
        />
      )}
      {openModal2 && (
        <ModalDisclaimer
          open={openModal2}
          onClose={() => setOpenModal2(false)}
        />
      )}
      {openModal3 && (
        <ModalTerm open={openModal3} onClose={() => setOpenModal3(false)} />
      )}
      {openModal4 && (
        <ModalSafety open={openModal4} onClose={() => setOpenModal4(false)} />
      )}
    </>
  );
}
