import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { useStores } from "_common/hooks";
import i18next from "i18next";

function ModalDisclaimer({ open, onClose }) {
  const {
    authStore: { general },
  } = useStores();

  return (
    <Portal>
      <div
        className="modal modal-full"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="mask" />
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="font-bold">Disclaimer</h2>
            <div className="btn-close" onClick={() => onClose()}>
              X
            </div>
          </div>
          <div className="classic-bet-now">
            <div className="content">
              <div className="rules__content">
                <p>
                  Trading cryptocurrency contracts involves a high level of
                  risk. The prices of cryptocurrencies fluctuate on a daily
                  basis. Due to price fluctuations, you as an investor may gain
                  asset appreciation or suffer asset loss at any time. The value
                  of any currency can fluctuate significantly. Opening a long or
                  short position in the cryptocurrency futures market always
                  carries an inherent risk of loss. Please note that the
                  interpretation of any special marked words in this article is
                  consistent with the interpretation in the terms of use
                  published by BITTOKEN.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  Cryptocurrency transactions carry specific risks that are
                  different from those of other fiat currencies, goods or
                  commodities in the market.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  {`Unlike most currencies that are backed by government reserves
                  or other legal entities, and commodities like silver and gold,
                  cryptocurrency is a "flat" currency, backed only by
                  mathematics, technology, and user trust. The currency is
                  absolutely decentralized, so in a crisis there is no authority
                  that can take corrective action to protect the value of the
                  cryptocurrency or issue more currency.`}
                </p>
                <p>
                  <br />
                </p>
                <p>
                  Cryptocurrency is an autonomous, largely unregulated global
                  payment system. When using cryptocurrencies, traders place
                  their trust in a digital, decentralized, and mostly anonymous
                  system that relies on peer-to-peer (p2p) networks and
                  cryptography to maintain its integrity.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  Cryptocurrency trading is susceptible to irrational or
                  rational bubbles or absolute loss of confidence, which can
                  lead to a collapse in the demand/supply relationship. Any
                  action, even with a minor correlation to a cryptocurrency,
                  such as unexpected changes imposed by currency developers,
                  government crackdowns, or even a deflationary or inflationary
                  spiral, can destroy confidence in the currency. Confidence can
                  also collapse due to various technical issues, including that
                  the anonymity of the system may be compromised, funds may be
                  lost or stolen, and hackers or governments (albeit for
                  different purposes) may prevent the settlement of
                  cryptocurrency transactions. Therefore, the trading of
                  cryptocurrency contracts is also affected by this situation.
                  Users should carefully evaluate whether their financial
                  situation, risk tolerance, professional knowledge and skills,
                  and qualifications (if necessary) are suitable for opening
                  cryptocurrency contract positions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalDisclaimer;
