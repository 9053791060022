import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useNotify } from "_common/component/NotifyProvider";
import { formatNumber } from "_common/utils/formatValue";
import { Link, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import candlestick from "assets/img/icon/candlestick.png";
import classNames from "classnames";
import i18next from "i18next";
import HeaderNav from "_common/component/Layout/Header/HeaderNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

import GameBoard from "./GameBoard";
import HistoryPrice from "./HistoryPrice";
import HistoryTrade from "./HistoryTrade";
import ListCoin from "./ListCoin";

const SpotTradeMobilePage = observer(() => {
  const {
    authStore: { games, user },
  } = useStores();

  const { updatePrice } = useNotify();

  const [params] = useSearchParams();
  const symbol = params?.get("symbol") || "btcusdt";
  const [activeHis, setActiveHis] = useState("bet");

  const [openModal, setOpenModal] = useState(false);
  const [game_id, setGameId] = useState(games[0]?.code);
  const { data: dataPrice, refetch: getPrice } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const priceBtc = useMemo(
    () => dataPrice?.find((obj) => obj?.symbol === game_id?.toUpperCase()),
    [dataPrice, game_id]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!priceBtc?.lastPrice) return;
    updatePrice({ user_id: user?.id, lastPrice: priceBtc?.lastPrice });
  }, [priceBtc?.lastPrice, user]);

  useEffect(() => {
    setGameId(symbol);
  }, [symbol]);

  return (
    <>
      <HeaderNav title={i18next.t("spot")} />
      <div className="content_title flexTab ">
        <div className="active-tab">{i18next.t("cryptocurrency")}</div>
      </div>
      <div className="feature-page spot-page">
        <div className="flex justify-between" style={{ border: "none" }}>
          <div className="content_title_l tleft">
            <div className="mu-btn" onClick={() => setOpenModal(true)}>
              <FontAwesomeIcon icon={faCog} className="text-green" />
            </div>
            <span className="text-uppercase text-[18px] ">
              {game_id?.split("usdt")?.[0]}
            </span>
          </div>
          <div className="content_title_r tright flex items-center justify-end">
            <Link to="/trade">
              <img src={candlestick} alt="" className="w-[25px] h-[25px]" />
            </Link>
          </div>
        </div>
        <div className="flex">
          <div className="flex-1">
            <GameBoard game_id={game_id} data_price={priceBtc} />
          </div>
          <div className="flex-1">
            <HistoryPrice />
          </div>
        </div>
        <div className="fccbox" />
        <div className="tabs">
          <div
            className={classNames("tab", {
              active: activeHis === "bet",
            })}
            onClick={() => setActiveHis("bet")}
          >
            {i18next.t("current")}
          </div>
          <div
            className={classNames("tab", {
              active: activeHis === "complete",
            })}
            onClick={() => setActiveHis("complete")}
          >
            {i18next.t("history_entrust")}
          </div>
        </div>
        <HistoryTrade status={activeHis} />
      </div>
      {openModal && (
        <ListCoin open={openModal} onClose={() => setOpenModal(false)} />
      )}
    </>
  );
});

export default SpotTradeMobilePage;
