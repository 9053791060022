import React from "react";
import { Portal } from "react-portal";
import Usdt from "./Usdt";
import Forex from "./Forex";
import Commodity from "./Commodity";
import CoinIndex from "./CoinIndex";

export default function ListCoin({ type, open, onClose }) {
  return (
    <Portal>
      <div className="left-draw" style={{ display: open ? "block" : "none" }}>
        <div className="mask" onClick={onClose} />
        <div className="content">
          {type === "forex" && <Forex onClose={onClose} />}
          {type === "commodity" && <Commodity onClose={onClose} />}
          {type === "index" && <CoinIndex onClose={onClose} />}
          {type === "cryptocurrency" && <Usdt onClose={onClose} />}
        </div>
      </div>
    </Portal>
  );
}
