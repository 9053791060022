import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

function SelectItem({ children, isSelect, isDisable, onChange }) {
  const ref = useRef(null);

  return (
    <li
      id="headlessui-listbox-option-14"
      role="presentation"
      onClick={() => !isDisable && onChange()}
    >
      <div
        className={classNames("sbui-listbox-option", {
          "sbui-listbox-option--disabled": isDisable,
        })}
      >
        <div
          className={classNames("sbui-listbox-option__inner", {
            "text-[#0081cc]": isSelect,
          })}
        >
          <span>
            <span>{children}</span>
          </span>
        </div>
      </div>
    </li>
  );
}

export default SelectItem;
