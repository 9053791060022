import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { FundApi } from "states/api/fund";
import logo from "assets/img/logo.png";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "_common/hooks";
import { get } from "lodash";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { UserFundApi } from "states/api/userFund";
import { NotificationManager } from "react-notifications";

const schema = yup
  .object({
    amount: yup
      .number()
      .min(0, `${i18next.t("enter_large_amount_msg")} 0`)
      .required(i18next.t("verify_require")),
  })
  .required();

export default function FundItem({ item }) {
  const navigate = useNavigate;
  const queryClient = useQueryClient();
  const params = useParams();
  const id = item?.id;
  const {
    authStore: { user, user_wallet, display_balance },
  } = useStores();
  const [amount, setAmount] = useState();

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate, isLoading } = useMutation(
    (variables) => UserFundApi.create({ params: variables }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
        setAmount();
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const profitPlan = useMemo(() => {
    const date = Number(get(item, "date", 0));
    const amountConvert = Number(amount) || 0;
    const max_profit = Number(get(item, "max_profit", 0)) / 100;
    return max_profit * amountConvert * date;
  }, [item, amount]);

  const onSave = () => {
    if (isLoading) return null;

    // if (Number(amount) > Number(item?.max)) {
    //   return NotificationManager.error(
    //     `${i18next.t("enter_smaller_deposit_amount")} ${formatNumber(
    //       item?.max
    //     )}`
    //   );
    // }

    if (Number(amount) < Number(item?.min)) {
      return NotificationManager.error(
        `${i18next.t("enter_large_deposit_amount")} ${formatNumber(item?.min)}`
      );
    }

    mutate({
      fund_id: id,
      amount,
      profit: profitPlan,
    });

    return null;
  };

  return (
    <div key={item?.id} className="item">
      <div className="py-[15px] px-[20px]">
        <div className="flex  justify-between gap-2">
          <div className="flex items-center gap-4">
            <span className="text-lg font-bold">{item?.name}</span>
          </div>
        </div>
        <div className="tx_inputbox">
          <div className="tx_inputbox_l !w-[80%]">
            <input
              type="number"
              inputMode="decimal"
              min={0}
              onChange={(e) => setAmount(e?.target?.value)}
              autoComplete="off"
              className="w-full h-[30px] !px-0"
              placeholder={`Number of Deposits ${formatNumber(item?.max)}`}
            />
          </div>
          <div className="tx_inputbox_r !w-[20%]">
            <div className="inputbox_r_r !w-full !text-right">
              <span className="fzmmm text-[#b1b1b1] !text-[14px]">USDT</span>
            </div>
          </div>
        </div>
        <h5 className="mt-[10px] text-[16px]">
          Total revenue:{" "}
          <b className="text-[18px]">
            {formatNumber(profitPlan, "0,0.000000")}
          </b>{" "}
          USDT
        </h5>
        <div className="flex  justify-between items-center gap-2 mt-[5px]">
          <div className="flex flex-col items-center">
            <h4 className="text-[20px] font-bold text-center text-[#78C9EB]">
              {formatNumber(item?.max_profit, "0,0.[000]")}% [USDT]
            </h4>
          </div>
          <div className="flex flex-col items-center">
            <h4 className="text-[20px] font-bold text-center text-[#78C9EB]">
              {formatNumber(item?.date)} Day
            </h4>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="sbui-btn  btn-fund-add uppercase"
        onClick={() => onSave()}
      >
        {i18next.t("buy_2")}
      </button>
    </div>
  );
}
