import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import { useNotify } from "_common/component/NotifyProvider";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import imgEmpty from "assets/img/empty-dark.png";

import GameChart from "./GameChart";
import HistoryPrice from "./HistoryPrice";

const SpotTradeDesktopPage = observer(() => {
  const {
    authStore: { games, user },
  } = useStores();

  const navigate = useNavigate();
  const { updatePrice } = useNotify();

  const [params] = useSearchParams();
  const symbol = params?.get("symbol") || "btcusdt";
  const time = params?.get("time") || "1m";
  const theme = params?.get("theme") || "dark";

  const [game_id, setGameId] = useState(games[0]?.code);
  const [isLight, setIsLight] = useState(false);
  const [buyType, setBuyType] = useState(1);
  const [sellType, setSellType] = useState(1);
  const [activeTab, setActiveTab] = useState(1);

  const { data: dataPrice, refetch: getPrice } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const priceBtc = useMemo(
    () => dataPrice?.find((obj) => obj?.symbol === game_id?.toUpperCase()),
    [dataPrice, game_id]
  );

  useEffect(() => {
    if (!theme) return;
    setIsLight(theme === "light");
  }, [theme]);

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!priceBtc?.lastPrice) return;
    updatePrice({ user_id: user?.id, lastPrice: priceBtc?.lastPrice });
  }, [priceBtc?.lastPrice, user]);

  useEffect(() => {
    setGameId(symbol);
  }, [symbol]);

  return (
    <div className="css-1wr4jig !bg-[#151617] !border-t-[1px] !border-[#151617]">
      <div className="w-full min-h-[880px] flex">
        <div className="w-[20%] min-h-[900px] pr-[10px] float-left">
          <div className="w-full min-h-[1009px] bg-[#1a1b1c]">
            <div className="w-full h-full px-[10px]">
              <div className="w-full">
                <div className="dong_sel_box">
                  <span className="fcgs dong_sel_span">USDT</span>
                </div>
              </div>
              <div className="symbol_list">
                <div className="usdt-shadow">
                  <div className="sy_list_box">
                    <div className="sy_list_boxl">
                      <span className="f14 fccs">Cặp tiền</span>
                    </div>
                    <div className="sy_list_boxl fccs f14 tcc !text-center">
                      Số lượng 24H
                    </div>
                    <div className="sy_list_boxr fccs f14">Tăng</div>
                  </div>
                </div>
                <div className="usdt-shadow">
                  <Link to="/contract?coin=btc">
                    <div className="sy_list_box">
                      <div className="sy_list_boxl">
                        <span className="f12 fcf">BTC/USDT</span>
                      </div>
                      <div className="sy_list_boxl f12 tcc !text-center">
                        <span className="fzmm red">25847.25</span>
                      </div>
                      <div className="sy_list_boxr f12">
                        <span className="fzmm red">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 320 512"
                            fill="#ff0000"
                            className="inline"
                          >
                            <path d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
                          </svg>
                          0.19%
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[60%] min-h-[900px] float-left">
          <div className="w-full h-[50px] border-b-[3px] border-[#151617]">
            <div className="klinetitle">
              <div className="klinetitle_l">
                <p className="newpricebox fw f12 fcgs">BTC/USDT</p>
                <p className="changebox fw f12 fcgs">Giao dịch tiền tệ</p>
              </div>
              <div className="klinetitle_l">
                <p className="newpricebox fw closeprice !text-[22px]">
                  <span className="red fw !text-[22px]">25757.74</span>
                </p>
                <p className="changebox fw">
                  <span className="red fw">-0.54%</span>
                </p>
              </div>
              <div className="klinetitle_r">
                <div className="col-4 klinetitle-s-box">
                  <div className="newpricebox f12 fccs">Thấp nhất</div>
                  <div className="changebox f12 fccs">25716.75</div>
                </div>
                <div className="col-4 klinetitle-s-box">
                  <div className="newpricebox f12 fccs">Cao nhất</div>
                  <div className="changebox f12 fccs">25950.48</div>
                </div>
                <div className="col-4 klinetitle-s-box">
                  <div className="newpricebox f12 fccs">24hsố lượng</div>
                  <div className="changebox f12 fccs">2441.7985714014</div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[600px] bg-[#1a1b1c] p-[5px]">
            <div className="w-full h-full p-[8px]">
              <div className="changInterval flex">
                <span className="interval-item fcf !text-[14px] !border-[0]">
                  Thành Công
                </span>
                <div className="interval-item fcf active">1 M</div>
                <div className="interval-item fcf">5 M</div>
                <div className="interval-item fcf">15 M</div>
                <div className="interval-item fcf">30 M</div>
                <div className="interval-item fcf">60 M</div>
                <div className="interval-item fcf">4 H</div>
                <div className="interval-item fcf">1 Day</div>
              </div>
              <GameChart game_id={game_id} isLight={isLight} />
            </div>
          </div>
          <div className="w-full h-[360px] border-t-[5px] border-[#151617]">
            <div className="w-full h-[360px]">
              <div className="contentbox !w-full !p-[0]">
                <div className="tradebox !w-full !p-[0]">
                  <div className="tradebox_l !w-full !p-[0] !px-[25px]">
                    <div className="formbox max-w-[50%] px-[15px] klinetitle-s-box klinetitle-s-box-l">
                      <div className="w-full h-[30px] mb-[10px]">
                        <div className="w-[30%] h-[30px] leading-[30px] float-left">
                          <span className="f14 fcc">Có sẵn</span>
                        </div>
                        <div className="w-[70%] h-[30px] leading-[30px] text-right float-left">
                          <span className="f14 fcc">1359897.13390000</span>
                          <span className="f14 fcc">USDT</span>
                        </div>
                      </div>
                      <div className="formbox_op">
                        <div
                          className={`formbox_op_list jy-btn ${
                            buyType === 1 && "btn_bg_color"
                          }`}
                          onClick={() => setBuyType(1)}
                        >
                          <span
                            className={`f12 ${
                              buyType === 1
                                ? "btn_bg_color_tcg"
                                : "btn_bg_color_tch"
                            }`}
                          >
                            Giới hạn hoa hồng giá
                          </span>
                        </div>
                        <div
                          className={`formbox_op_list jy-btn ${
                            buyType === 2 && "btn_bg_color"
                          }`}
                          onClick={() => setBuyType(2)}
                        >
                          <span
                            className={`f12 ${
                              buyType === 2
                                ? "btn_bg_color_tcg"
                                : "btn_bg_color_tch"
                            }`}
                          >
                            Ủy ban giá thị trường
                          </span>
                        </div>
                      </div>
                      {buyType === 1 ? (
                        <>
                          <div className="inputbox !h-[36px] flex">
                            <div className="inputbox_float flex-1">
                              <div className="klinetitle-s-box input-desc tcc tcc btn_bg_color_tch f12 ">
                                Giá mua
                              </div>
                              <input type="text" className="fcc xjinput fcf" />
                            </div>
                            <div className="inputbox_float !w-[20%] float-right">
                              <div className="input_bi borderright">
                                <i className="bi bi-dash bistyle" />
                              </div>
                              <div className="input_bi">
                                <i className="bi bi-plus bistyle" />
                              </div>
                            </div>
                          </div>
                          <div className="inputbox !h-[36px] flex">
                            <div className="inputbox_float flex-1">
                              <div className="klinetitle-s-box input-desc tcc tcc btn_bg_color_tch f12">
                                Mua
                              </div>
                              <input
                                type="text"
                                className="fcf xjinput"
                                placeholder="Số lượng"
                                autoComplete="off"
                                step="1"
                                min="0"
                              />
                            </div>
                            <div className="inputbox_float !w-[20%] float-right">
                              <div className="input_bi borderright">
                                <i className="bi bi-dash bistyle" />
                              </div>
                              <div className="input_bi">
                                <i className="bi bi-plus bistyle" />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="inputbox !h-[36px] bg-[#2c2d2e]">
                            <div className="inputbox_float flex-1">
                              <div className="klinetitle-s-box input-desc tcc fcf f12" />
                              <input
                                type="text"
                                className="fcc xjinput sjxjinput !bg-[#2c2d2e]"
                                placeholder="Dựa trên giá tốt nhất của thị trường"
                                disabled="disabled"
                              />
                            </div>
                          </div>
                          <div className="inputbox !h-[36px] bg-[#2c2d2e]">
                            <div className="inputbox_float flex-1">
                              <div className="klinetitle-s-box input-desc fcf f12" />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="blbox">
                        <div className="blbox_1">
                          <div className="blbox_3 bgf5" />
                          <div className="blbox_4">
                            <span className="f12 fcc">25%</span>
                          </div>
                        </div>
                        <div className="blbox_1">
                          <div className="blbox_3 bgf5" />
                          <div className="blbox_4">
                            <span className="f12 fcc">50%</span>
                          </div>
                        </div>
                        <div className="blbox_1">
                          <div className="blbox_3 bgf5" />
                          <div className="blbox_4">
                            <span className="f12 fcc">75%</span>
                          </div>
                        </div>
                        <div className="blbox_2">
                          <div className="blbox_3 bgf5" />
                          <div className="blbox_4">
                            <span className="f12 fcc">100%</span>
                          </div>
                        </div>
                      </div>
                      <div className="inputbox !h-[36px] flex">
                        <div className="inputbox_float flex-1">
                          <div className="klinetitle-s-box input-desc tcc tcc btn_bg_color_tch f12">
                            Số tiền giao dịch
                          </div>
                          <input
                            type="text"
                            className="fcf xjinput"
                            autoComplete="off"
                            step="1"
                            min="0"
                          />
                        </div>
                        <div className="inputbox_float !w-[10%] leading-[36px] text-center float-right">
                          <span className="f12 fcc">USDT</span>
                        </div>
                      </div>
                      <div className="w-full h-[36px] leading-[36px] text-white text-center bg-[#0ecb81] rounded-[5px] mt-[10px]">
                        <span className="f12 !text-white">Mua</span>
                      </div>
                    </div>
                    <div className="formbox max-w-[50%] px-[15px] klinetitle-s-box">
                      <div className="w-full h-[30px] mb-[10px]">
                        <div className="w-[30%] h-[30px] leading-[30px] float-left">
                          <span className="f14 fcc">Có sẵn</span>
                        </div>
                        <div className="w-[70%] h-[30px] leading-[30px] text-right float-left">
                          <span className="f14 fcc">24.67895219</span>
                          <span className="f14 fcc">BTC</span>
                        </div>
                      </div>
                      <div className="formbox_op">
                        <div
                          className={`formbox_op_list jy-btn ${
                            sellType === 1 && "btn_bg_color"
                          }`}
                          onClick={() => setSellType(1)}
                        >
                          <span
                            className={`f12 ${
                              sellType === 1
                                ? "btn_bg_color_tcg"
                                : "btn_bg_color_tch"
                            }`}
                          >
                            Giới hạn hoa hồng giá
                          </span>
                        </div>
                        <div
                          className={`formbox_op_list jy-btn ${
                            sellType === 2 && "btn_bg_color"
                          }`}
                          onClick={() => setSellType(2)}
                        >
                          <span
                            className={`f12 ${
                              sellType === 2
                                ? "btn_bg_color_tcg"
                                : "btn_bg_color_tch"
                            }`}
                          >
                            Ủy ban giá thị trường
                          </span>
                        </div>
                      </div>
                      {sellType === 1 ? (
                        <>
                          <div className="inputbox !h-[36px] flex">
                            <div className="inputbox_float flex-1">
                              <div className="klinetitle-s-box input-desc tcc tcc btn_bg_color_tch f12 ">
                                Giá bán
                              </div>
                              <input type="text" className="fcf xjinput" />
                            </div>
                            <div className="inputbox_float !w-[20%] float-right">
                              <div className="input_bi borderright">
                                <i className="bi bi-dash bistyle" />
                              </div>
                              <div className="input_bi">
                                <i className="bi bi-plus bistyle" />
                              </div>
                            </div>
                          </div>
                          <div className="inputbox !h-[36px] flex">
                            <div className="inputbox_float flex-1">
                              <div className="klinetitle-s-box input-desc tcc tcc btn_bg_color_tch f12">
                                Doanh số bán hàng
                              </div>
                              <input
                                type="text"
                                className="fcf xjinput"
                                placeholder="Số lượng"
                                autoComplete="off"
                                step="1"
                                min="0"
                              />
                            </div>
                            <div className="inputbox_float !w-[20%] float-right">
                              <div className="input_bi borderright">
                                <i className="bi bi-dash bistyle" />
                              </div>
                              <div className="input_bi">
                                <i className="bi bi-plus bistyle" />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="inputbox !h-[36px] bg-[#2c2d2e]">
                            <div className="inputbox_float flex-1">
                              <div className="klinetitle-s-box input-desc tcc fcf f12" />
                              <input
                                type="text"
                                className="fcc xjinput sjxjinput !bg-[#2c2d2e]"
                                placeholder="Dựa trên giá tốt nhất của thị trường"
                                disabled="disabled"
                              />
                            </div>
                          </div>
                          <div className="inputbox !h-[36px] bg-[#2c2d2e]">
                            <div className="inputbox_float flex-1">
                              <div className="klinetitle-s-box input-desc fcf f12" />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="blbox">
                        <div className="blbox_1">
                          <div className="blbox_3 bgf5" />
                          <div className="blbox_4">
                            <span className="f12 fcc">25%</span>
                          </div>
                        </div>
                        <div className="blbox_1">
                          <div className="blbox_3 bgf5" />
                          <div className="blbox_4">
                            <span className="f12 fcc">50%</span>
                          </div>
                        </div>
                        <div className="blbox_1">
                          <div className="blbox_3 bgf5" />
                          <div className="blbox_4">
                            <span className="f12 fcc">75%</span>
                          </div>
                        </div>
                        <div className="blbox_2">
                          <div className="blbox_3 bgf5" />
                          <div className="blbox_4">
                            <span className="f12 fcc">100%</span>
                          </div>
                        </div>
                      </div>
                      {sellType === 1 ? (
                        <div className="inputbox !h-[36px] flex">
                          <div className="inputbox_float flex-1">
                            <div className="klinetitle-s-box input-desc tcc btn_bg_color_tch f12">
                              Số tiền giao dịch
                            </div>
                            <input
                              type="text"
                              className="fcf xjinput"
                              autoComplete="off"
                              step="1"
                              min="0"
                            />
                          </div>
                          <div className="inputbox_float !w-[10%] leading-[36px] text-center float-right">
                            <span className="f12 fcc">USDT</span>
                          </div>
                        </div>
                      ) : (
                        <div className="inputbox !h-[36px] flex">
                          <div className="inputbox_float flex-1">
                            <div className="klinetitle-s-box input-desc tcc btn_bg_color_tch f12">
                              Số tiền giao dịch
                            </div>
                            <input
                              type="text"
                              className="fcf xjinput"
                              autoComplete="off"
                              step="1"
                              min="0"
                            />
                          </div>
                          <div className="inputbox_float !w-[10%] leading-[36px] text-center float-right">
                            <span className="f12 fcc">btc</span>
                          </div>
                        </div>
                      )}
                      <div className="w-full h-[36px] leading-[36px] text-white text-center bg-[#f5465c] rounded-[5px] mt-[10px]">
                        <span className="f12 !text-white">Bán</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[20%] min-h-[800px] pl-[10px] float-left">
          <div className="w-full min-h-[1009px] bg-[#1a1b1c]">
            <div className="w-full h-[40px] px-[15px]">
              <div className="min-w-[20%] h-[40px] leading-[40px] px-[5px]">
                <span className="f12 fccs">Xử lý</span>
              </div>
              <div className="w-full mt-[20px]">
                <HistoryPrice />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full min-h-[360px] bg-[#151617] border-t-[1px] border-[#2c2d2e] mt-[10px]">
        <div className="order-top">
          <div
            className={`order-top-span order-top-current fcfs ${
              activeTab === 1 && "order-top-select"
            } `}
            onClick={() => setActiveTab(1)}
          >
            Hiện đang giao dịch
          </div>
          <div
            className={`order-top-span order-top-current fcfs ${
              activeTab === 2 && "order-top-select"
            } `}
            onClick={() => setActiveTab(2)}
          >
            Lịch sử
          </div>
          <div className="refresh-icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="inline"
            >
              <path
                d="M42 8V24"
                stroke="#303131"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 24L6 40"
                stroke="#303131"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 24C6 33.9411 14.0589 42 24 42C28.8556 42 33.2622 40.0774 36.5 36.9519"
                stroke="#303131"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M42.0007 24C42.0007 14.0589 33.9418 6 24.0007 6C18.9152 6 14.3223 8.10896 11.0488 11.5"
                stroke="#303131"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="order-main">
          <div
            className={`table-box order-main-table-current ${
              activeTab === 1 ? "!block" : "!hidden"
            }`}
          >
            <table className="table fcf order-main-table w-full">
              <thead>
                <tr className="fccs">
                  <td>ID</td>
                  <td>Giao dịch</td>
                  <td>Hướng đi</td>
                  <td>Tiểu bang</td>
                  <td>Thủ tục được ủy thác</td>
                  <td>Giá giới hạn giao dịch</td>
                  <td>Thời gian ủy thác</td>
                  <td>Vận hành</td>
                </tr>
              </thead>
              <tbody className="bg-[#151617]" />
            </table>
            <div className="table-history-more">
              <img src={imgEmpty} alt="" className="empty-svg inline" />
              <p className="fccs">Chưa có giao dịch</p>
            </div>
          </div>
          <div
            className={`table-box order-main-table-history ${
              activeTab === 2 ? "!block" : "!hidden"
            }`}
          >
            <table className="table tcc order-main-table w-full">
              <thead>
                <tr className="fccs">
                  <td>Loại giao dịch</td>
                  <td>Cặp tiền</td>
                  <td>Lệnh</td>
                  <td>Tình trạng</td>
                  <td>Số tiền giao dịch</td>
                  <td>Số tiền thực thế</td>
                  <td>Thời gian mở lệnh</td>
                  <td>Thời gian đóng lệnh</td>
                </tr>
              </thead>
              <tbody>
                <tr className=" f12">
                  <td className="fcy f14">Giao dịch giá thị trường</td>
                  <td className="fcf f14">BTC/USDT</td>
                  <td className="f14 fw fgreen">Mua</td>
                  <td className="fcf f14">Hoàn thành</td>
                  <td className="fcf f14">263.49500000</td>
                  <td className="fcf f14">26349.50000000</td>
                  <td className="fcf f14">2023-09-08 11:45:51</td>
                  <td className="fcf f14">2023-09-08 11:45:58</td>
                </tr>
              </tbody>
            </table>
            <div className="table-history-more">
              <Link to="/trade/order-history">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 384 512"
                  fill="#e6ecf2"
                  className="inline"
                >
                  <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                </svg>
                <span className="tcc !text-[16px] !text-[#007bff] ml-[5px]">
                  Xem thêm
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SpotTradeDesktopPage;
